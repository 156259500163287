/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';

import imgNikolasSchaal from '../../assets/Images/employees/Foto_Nikolas.jpg';
import imgLinHimmelmann from '../../assets/Images/employees/Foto_Lin.jpg';
import imgDaniel from '../../assets/Images/employees/Foto_Daniel.jpg';
import {alpha, useTheme} from "@mui/material/styles";
import {PaletteMode} from "@mui/material";

const mock = [
  {
    name: 'Daniel Politze',
    title: 'Product Owner',
    avatar: imgDaniel,
  },
  {
    name: 'Nikolas Schaal',
    title: 'Legal & Operations',
    avatar: imgNikolasSchaal,
  },
  {
    name: 'Lin Himmelmann',
    title: 'Research & Technology',
    avatar: imgLinHimmelmann,
  }
];

const TeamAndContact = (): JSX.Element => {
  const theme = useTheme();
  const mode = theme.palette.mode;

  return (
      <Box>
        <Box marginBottom={4}>
          <Box marginBottom={2}>
            <Typography
                align={'center'}
                variant="h3"
                color="text.primary"
                sx={{ fontWeight: 700 }}
            >
              Persönlich für Dich da:{' '}
              <Typography
                  color={'text.primary'}
                  component={'span'}
                  variant={'inherit'}
                  sx={{
                    background: `linear-gradient(180deg, transparent 82%, ${alpha(
                        mode === 'light' as PaletteMode ? theme.palette.secondary.main : theme.palette.primary.main,
                        0.3,
                    )} 0%)`,
                  }}
              >
                Unser Team
              </Typography>
            </Typography>
          </Box>
          <Typography
              variant="h6"
              align={'center'}
              color={'text.secondary'}
              data-aos={'fade-up'}
          >
            Wenn Du mehr über uns wissen willst - Schreib uns einfach eine E-Mail
            {/*<br />*/}
            {/*Unseren unwiderstehlichen Reiseplaner, den es so noch nirgends gibt. Damit wird die Planung deines*/}
            {/*Camping-Abenteuers mega easy!*/}
          </Typography>
          <Box marginTop={2} display={'flex'} justifyContent={'center'}>
            <Button
                color={'primary'}
                variant={'outlined'}
                size={'large'}
                startIcon={
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      width={20}
                      height={20}
                  >
                    <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                  </svg>
                }
                onClick={(e) => {
                  window.location.href = 'mailto:support@vanlife-travel.ch';
                  e.preventDefault();
                }}
                title="support@vanlife-travel.ch"
            >
              EMail
            </Button>
          </Box>
        </Box>

        <Grid container spacing={2}>
          {mock.map((item, i) => (
            <Grid item xs={6} md={3} key={i}>
              <ListItem
                disableGutters
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' },
                  alignItems: { xs: 'flex-start', sm: 'center' },
                }}
              >
                <ListItemAvatar>
                  <Box
                    component={Avatar}
                    width={{ xs: 80, sm: 80, md: 120 }}
                    height={{ xs: 80, sm: 80, md: 120 }}
                    src={item.avatar}
                    marginRight={2}
                  />
                </ListItemAvatar>
                <ListItemText primary={item.name} secondary={item.title} />
              </ListItem>
            </Grid>
          ))}
        </Grid>
      </Box>
  );
};

export default TeamAndContact;
