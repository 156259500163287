import React, { useState } from 'react';
import { Typography } from '@mui/material';

const ImpressumModal = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleModal = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div>
            {/* Typography-Komponente zum Öffnen des Modals */}
            <Typography
                variant="body1"
                color="secondary"
                fontFamily={'bubblegum-sans'}
                onClick={toggleModal}
                style={{cursor: 'pointer', textDecoration:'none'}}
            >
                Impressum
            </Typography>

            {/* Modal wird angezeigt, wenn isOpen true ist */}
            {isOpen && (
                <div
                    style={{
                        position: 'fixed',
                        zIndex: 1,
                        left: 0,
                        top: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Halbtransparentes Schwarz
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                    onClick={toggleModal}
                >
                    {/* Modal-Inhalt */}
                    <div
                        style={{
                            backgroundColor: 'white',
                            padding: '20px',
                            borderRadius: '10px',
                            width: '80vh',
                            height: '80vh',
                            overflowY: 'auto',
                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                            textAlign: 'left',
                            position: 'relative'
                        }}
                        onClick={e => e.stopPropagation()}
                    >
                        {/* Schließen-Button */}
                        <span
                            style={{
                                position: 'absolute',
                                top: '10px',
                                right: '10px',
                                fontSize: '24px',
                                cursor: 'pointer'
                            }}
                            onClick={toggleModal}
                        >
              &times;
            </span>
                        <Typography variant="h5" gutterBottom>
                            Impressum
                        </Typography>

                        <Typography variant="body1" gutterBottom>
                            Angaben gemäß Art. 3 Abs. 1 lit. s UWG (Schweiz)
                        </Typography>

                        <Typography variant="body1" gutterBottom>
                            <strong>Anbieter:</strong>
                            <br />
                            Vanlife Travel GmbH
                            <br />
                            Im Glockenacker 35
                            <br />
                            8053 Zürich
                            <br />
                            Schweiz
                        </Typography>

                        <Typography variant="body1" gutterBottom>
                            <strong>Vertretungsberechtigte:</strong>
                            <br />
                            Nikolas Schaal, Lin Himmelmann, Daniel Politze
                        </Typography>

                        <Typography variant="body1" gutterBottom>
                            <strong>Kontakt:</strong>
                            <br />
                            E-Mail: support@vanlife-travel.ch
                        </Typography>

                        <Typography variant="body1" gutterBottom>
                            <strong>UID-Nummer:</strong>
                            <br />
                            CHE-333.416.314
                        </Typography>

                        <Typography variant="body1" gutterBottom>
                            <strong>Haftungsausschluss:</strong>
                            <br/>
                            Der Anbieter übernimmt keine Gewähr für die Vollständigkeit, Richtigkeit und Aktualität der
                            bereitgestellten Inhalte. Jegliche Haftung für Schäden, die direkt oder indirekt aus der
                            Nutzung dieser Webseite entstehen, wird ausgeschlossen, soweit diese nicht auf Vorsatz oder
                            grober Fahrlässigkeit beruhen.
                        </Typography>

                        <Typography variant="body1" gutterBottom>
                            <strong>Urheberrecht:</strong>
                            <br/>
                            Die auf dieser Webseite veröffentlichten Inhalte und Werke unterliegen dem Schweizer
                            Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung oder sonstige Verwendung der
                            Inhalte bedarf der schriftlichen Zustimmung des jeweiligen Autors oder Erstellers.
                        </Typography>

                        <Typography variant="body1" gutterBottom>
                            <strong>Datenschutz:</strong>
                            <br/>
                            Der Schutz Ihrer persönlichen Daten ist uns wichtig. Informationen zur Verarbeitung
                            personenbezogener Daten finden Sie in unserer Datenschutzerklärung.
                        </Typography>

                        <Typography variant="body1" gutterBottom>
                            <strong>Streitbeilegung:</strong>
                            <br/>
                            Wir sind weder bereit noch verpflichtet, an Streitbeilegungsverfahren vor einer
                            Verbraucherschlichtungsstelle teilzunehmen.
                        </Typography>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ImpressumModal;