import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {mapErrorMessages} from '../../utils/errorHandling'
import {StayDetail, TourDetail, TourOverview} from "../../services/restserver-openapi";
import {
    cancelTour,
    changeStayAdditionalInformation,
    changeStayDuration,
    exchangeStay,
    getGeneratedTours,
    getTourDetail,
    getToursFromUser,
    postBookTour,
    reserveTour
} from "../../utils/Vanlifer/tour";
import {generateTourCoordinateList} from "../../utils/hereMapUtils";

let errorMessage: string | null;
export const defaultTourOverview: TourOverview = {
    tourId: 0,
    vanliferId: 0,
    name: "",
    price: undefined,
    rating: undefined,
    status: TourOverview.status.GENERATED,
    statusDate: undefined,
    stays: [],
    tourCompleted: false
}

export const defaultDetailTour: TourDetail = {
    tourId: 0,
    vanliferId: 0,
    name: "",
    price: 0,
    rating: 0,
    status: TourDetail.status.GENERATED,
    statusDate: 0,
    stays: [],
}

export type TourDistance = {
    tourId: number;
    distance: number;
}

export type TourState = {
    loading: boolean;
    error: string | null;
    tourOverview: TourOverview;
    choosedTourDetail: TourDetail;
    previousGeneratedTours: TourOverview[] | undefined;
    generatedTours: TourOverview[] | undefined;
    generatedCoordinates: string[] | null;
    tourDistances?: TourDistance[];
    detailedTourDistances?: number[];
    showStaticMap: boolean;
    mapCaptureFinished: boolean;
}

const initialState: TourState = {
    loading: false,
    error: null,
    tourOverview: defaultTourOverview,
    choosedTourDetail: defaultDetailTour,
    generatedTours: undefined,
    generatedCoordinates: null,
    tourDistances: [],
    detailedTourDistances: [],
    showStaticMap: false,
    mapCaptureFinished: false,
    previousGeneratedTours: undefined,
}

export const fetchToursFromUser = createAsyncThunk(
    'tour/fetchToursFromUser',
    async (inputObject: {sessionId?: string, vanliferId: number, tourStatus?: 'BOOKED' | 'CANCELED'}) => {
        const response = await getToursFromUser(inputObject.sessionId, inputObject.vanliferId, inputObject.tourStatus);
        return response;
    }
);

export const fetchAllGeneratedTours = createAsyncThunk(
    'tour/fetchAllGeneratedTours',
    async (inputObject: { fromDate: number, toDate: number, regionId: number, compoundTypes: string[] }) => {
        const response = await getGeneratedTours(inputObject.fromDate, inputObject.toDate, inputObject.regionId, inputObject.compoundTypes);
        return response;
    }
);
export const loadTourDetail = createAsyncThunk(
    'tour/getTourDetail',
    async (tourId: number) => {
        const response = await getTourDetail(tourId);
        return response;
    }
);

export const fetchTourDetails = createAsyncThunk(
    'tour/fetchTourInfo',
    async (tourId: number) => {
        const response = await reserveTour(tourId);
        return response;
    }
);

export const fetchEditedTourDetails = createAsyncThunk(
    'tour/fetchEditedTourDetails',
    async (inputObject: { tourId: number, stayId: number, chosenSiteId: number }) => {
        const response = await exchangeStay(inputObject.tourId, inputObject.stayId, inputObject.chosenSiteId);
        return response;
    }
);

export const asyncChangeStayDuration = createAsyncThunk(
    'tour/changeStayDuration',
    async (inputObject: { tourId: number, stayId: number, newFromDate: number, newToDate: number }) => {
        const response = await changeStayDuration(inputObject.tourId, inputObject.stayId, inputObject.newFromDate, inputObject.newToDate);
        return response;
    }
);

export const asyncChangeStayAdditionalInformation = createAsyncThunk(
    'tour/changeStayAdditionalInformation',
    async (inputObject: { tourId: number, stayId: number, newArrivalTime: string, newComment: string }) => {
        return changeStayAdditionalInformation(inputObject.tourId, inputObject.stayId, inputObject.newArrivalTime, inputObject.newComment);
    }
);

export const asyncPostBookTour = createAsyncThunk(
    'tour/postBookTour',
    async (tourId: number) => {
        const response = await postBookTour(tourId);
        return response;
    }
);

export const asyncCancelTour = createAsyncThunk(
    'tour/cancelTour',
    async (tourId: number) => {
        const response = await cancelTour(tourId);
        return response;
    }
);


export const tourSlice = createSlice({
        name: 'tour',
        initialState,
        reducers: {
            setGeneratedTours: (state, {payload}: PayloadAction<TourOverview[]>) => {
                state.generatedTours = payload;
            },
            setTour: (state, {payload}: PayloadAction<TourOverview>) => {
                state.tourOverview.tourId = payload.tourId;
                state.tourOverview.serviceFee = payload.serviceFee;
            },
            setTourDistances: (state, action) => {
                const newDistanceElement: TourDistance = {tourId: action.payload.tourId, distance: action.payload.distance};
                state.tourDistances!.push(newDistanceElement);
            },
            resetTourDistances: (state) => {
                state.tourDistances = [];
            },
            setDetailedDistanceList: (state, action) => {
                state.detailedTourDistances = action.payload.detailedDistanceList;
            },
            resetTours: (state) => {
                state.loading = false;
                state.error = null;
                state.tourOverview = defaultTourOverview;
                state.choosedTourDetail = defaultDetailTour;
                state.generatedTours = undefined;
                state.generatedCoordinates = null;
                state.tourDistances = [];
            },
            resetErrorStateTours:(state)=>{
                state.error = null;
            },
            setShowStaticMap: (state, action) => {
                state.showStaticMap = action.payload;
            },
            setMapCaptureFinished: (state, action) => {
                state.mapCaptureFinished = action.payload;
            }
        },
        extraReducers: (builder) => {
            builder.addCase(fetchAllGeneratedTours.pending, (state, {payload}) => {
                state.loading = true;
            }).addCase(fetchAllGeneratedTours.fulfilled, (state, {payload}) => {
                state.generatedTours = payload;
                if (state.previousGeneratedTours) {
                    state.previousGeneratedTours = [...state.previousGeneratedTours, ...payload];
                } else {
                    state.previousGeneratedTours = payload;
                }
                state.loading = false;
            }).addCase(fetchAllGeneratedTours.rejected, (state, action) => {
                if (action.error.message) {
                    errorMessage = action.error.message;
                    state.error = mapErrorMessages({errorMessage})
                }
                state.loading = false
            }).addCase(fetchTourDetails.pending, (state, {payload}) => {
                state.loading = true;
            }).addCase(fetchTourDetails.fulfilled, (state, {payload}) => {
                state.choosedTourDetail = payload;
                state.generatedCoordinates = generateTourCoordinateList(state.choosedTourDetail.stays as StayDetail[]);
                state.loading = false;
            }).addCase(fetchTourDetails.rejected, (state, action) => {
                if (action.error.message) {
                    errorMessage = action.error.message;
                    state.error = mapErrorMessages({errorMessage})
                }
                state.loading = false;
            }).addCase(fetchEditedTourDetails.pending, (state, {payload}) => {
                state.loading = true;
            }).addCase(fetchEditedTourDetails.fulfilled, (state, {payload}) => {
                state.choosedTourDetail = payload;
                state.generatedCoordinates = generateTourCoordinateList(state.choosedTourDetail.stays as StayDetail[]);
                state.loading = false;
            }).addCase(fetchEditedTourDetails.rejected, (state, action) => {
                if (action.error.message) {
                    errorMessage = action.error.message;
                    state.error = mapErrorMessages({errorMessage})
                }
                state.loading = false
            }).addCase(asyncPostBookTour.pending, (state) => {
                state.loading = true;
            }).addCase(asyncPostBookTour.fulfilled, (state, {payload}) => {
                state.choosedTourDetail = payload;
                state.loading = false;
            }).addCase(asyncPostBookTour.rejected, (state, action) => {
                state.error = mapErrorMessages({errorMessage});
            }).addCase(asyncChangeStayDuration.pending, (state) => {
                state.loading = true;
            }).addCase(asyncChangeStayDuration.fulfilled, (state, {payload}) => {
                state.choosedTourDetail = payload;
                state.generatedCoordinates = generateTourCoordinateList(state.choosedTourDetail.stays as StayDetail[]);
                state.loading = false;
            }).addCase(asyncChangeStayDuration.rejected, (state, action) => {
                state.error = mapErrorMessages({errorMessage});
            }).addCase(asyncChangeStayAdditionalInformation.pending, (state) => {
                state.loading = true;
            }).addCase(asyncChangeStayAdditionalInformation.fulfilled, (state, {payload}) => {
                state.choosedTourDetail = payload;
                state.generatedCoordinates = generateTourCoordinateList(state.choosedTourDetail.stays as StayDetail[]);
                state.loading = false;
            }).addCase(asyncChangeStayAdditionalInformation.rejected, (state, action) => {
                state.error = mapErrorMessages({errorMessage});
            }).addCase(loadTourDetail.pending, (state, {payload}) => {
                state.loading = true;
            }).addCase(loadTourDetail.fulfilled, (state, {payload}) => {
                state.choosedTourDetail = payload;
                state.generatedCoordinates = generateTourCoordinateList(state.choosedTourDetail.stays as StayDetail[]);
                state.loading = false;
            }).addCase(asyncCancelTour.pending, (state) => {
                state.loading = true;
            }).addCase(asyncCancelTour.fulfilled, (state, {payload}) => {
                state.loading = false;
                state.error = null;
                state.choosedTourDetail = defaultDetailTour;
            }).addCase(asyncCancelTour.rejected, (state, action) => {
                state.error = mapErrorMessages({errorMessage});
            })
        }
    }
);


export const {
    setTour,
    setGeneratedTours,
    resetTours,
    setTourDistances,
    setDetailedDistanceList,
    resetTourDistances,
    resetErrorStateTours,
    setShowStaticMap,
    setMapCaptureFinished,
} = tourSlice.actions;

export const tourReducer = tourSlice.reducer;
