import React, {useEffect} from 'react';
import {Grid, Typography} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {fetchAllSizes} from "../../store/Host/sitesManagement/sizesSlice";
import OfferImages from "../presentationalComponents/OfferImages";
import {getReadableSiteType} from "../../utils/typeUtils";

function SiteManagement() {
    const dispatch = useAppDispatch();
    const site = useAppSelector(state => state.site.site);
    const sizes = useAppSelector(state => state.sizes.sizes);

    useEffect(() => {
        dispatch(fetchAllSizes);
    })

    if (!site) {
        return (
            <Typography> Kein Angebot ausgewählt </Typography>
        )
    }

    const features = [
        { key: "electricity", label: "Strom", enabled: site.electricity },
        { key: "water", label: "Wasser", enabled: site.water },
        { key: "petsAllowed", label: "Haustiere erlaubt", enabled: site.petsAllowed },
        { key: "nonSmoking", label: "Nichtraucher", enabled: site.nonSmoking },
        { key: "tvConnection", label: "TV/ Kabelanschluss", enabled: site.tvConnection },
        { key: "cookingFacility", label: "Kochgelegenheit", enabled: site.cookingFacility },
        { key: "minibar", label: "Minibar", enabled: site.minibar },
        { key: "sharedBathroom", label: "WC zur Mitbenutzung", enabled: site.sharedBathroom },
        { key: "sharedShower", label: "Dusche zur Mitbenutzung", enabled: site.sharedShower },
        { key: "privateBathroom", label: "Eignes WC", enabled: site.privateBathroom },
        { key: "privateShower", label: "Eigene Dusche", enabled: site.privateShower },
        { key: "refrigerator", label: "Kühlschrank", enabled: site.refrigerator },
        { key: "balconyOrTerrace", label: "Balkon/Terrasse", enabled: site.balconyOrTerrace },
    ];

    return (
        <Grid container rowSpacing={3} columnSpacing={{xs: 1, sm: 2, md: 3}} justifyContent="space-between">
            <Grid item xs={12} sm={6} md={2}>
                <Typography> Platz/Zimmer Nr: {site.label}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <Typography> Freie Bezeichnung: {site.offer!.name}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
                <Typography> Fläche: {site.area} m²</Typography>
            </Grid>
            {site.siteType === "ROOM" || site.siteType === "PITCH" ? (
                <Grid item xs={12} sm={6} md={3}>
                    <Typography> Grössenbezeichnung: {sizes.map(size => size.sizeId === site.sizeId ? size.name : "")}</Typography>
                </Grid>
            ) : null}
            <Grid item xs={12} sm={6} md={3}>
                <Typography> Min. Belegungsanzahl: {site.minimumOccupancy}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <Typography> Max. Belegungsanzahl: {site.maximumOccupancy}</Typography>
            </Grid>

            <Grid item xs={12} md={12}>
                <Typography component={"article"} variant="body1"> Beschreibung: {site.offer?.description}</Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
                {features.map((feature) =>
                    feature.enabled ? (
                        <FormControlLabel
                            key={feature.key}
                            control={
                                <Checkbox
                                    checked={feature.enabled}
                                    disabled
                                    name={feature.key}
                                />
                            }
                            label={feature.label}
                        />
                    ) : null
                )}
            </Grid>
            <Grid item xs={12} md={12}>
                <Typography component={"article"} variant="body1"> Gästeinformationen zum {getReadableSiteType(site)}: {site.guestInformation}</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
                <Typography component={"article"} variant="body1"> Bilder: </Typography>
                { site.imageIds && site.imageIds.length > 0 ?
                    <OfferImages imageIds={site.imageIds} altText={"Bild vom Stellplatz " + site.label}/>
                    :
                    <Typography component={"article"} variant="body1"> Keine Bilder vorhanden </Typography>
                }
            </Grid>
        </Grid>
    );
}

export default SiteManagement;

