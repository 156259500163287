import React, {useEffect, useState} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Radio,
    RadioGroup
} from '@mui/material';
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
import {
    defaultCompound,
    fetchCompoundEntitlements,
    fetchCompounds,
    setSelectedCompound
} from "../../store/Host/compoundsSlice";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {Compound} from "../../services/restserver-openapi";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import {getReadableCompoundType} from "../../utils/typeUtils"; // Import the action

function CompoundSwitcher({ isMenuButton = false }) {
    const [open, setOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState<Compound>(defaultCompound);
    const hostId = useAppSelector(state => state.login.userSpecificationId);
    const compounds: Compound[] = useAppSelector(state => state.compounds.compounds);
    const selectedCompound = useAppSelector(state => state.compounds.selectedCompound);

    const dispatch = useAppDispatch();

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setSelectedValue(selectedCompound)
        setOpen(false);
    };

    const handleSave = () => {
        dispatch(setSelectedCompound(selectedValue));
        handleClose();
    };

    useEffect(() => {
        if (compounds.length > 0 && !selectedCompound) {
            setSelectedValue(compounds[0]);
            dispatch(setSelectedCompound(compounds[0]));
            if (compounds[0].compoundId && compounds[0].compoundId !== 0) {
                dispatch(fetchCompoundEntitlements(compounds[0].compoundId));
            }
        } else if (selectedCompound) {
            setSelectedValue(selectedCompound);
            if (selectedCompound.compoundId && selectedCompound.compoundId !== 0) {
                dispatch(fetchCompoundEntitlements(selectedCompound.compoundId));
            }

        }
    }, [compounds, dispatch, selectedCompound]);

    useEffect(() => {
        if (hostId) {
            dispatch(fetchCompounds(hostId));
        }
    }, [dispatch, hostId]);


    if (compounds && compounds.length > 0) {
        return (
            <>
            {isMenuButton ? (
                    <Button
                        endIcon={<ChangeCircleOutlinedIcon />}
                        color='primary'
                        onClick={handleOpen}
                        fullWidth
                    >
                        {selectedCompound ? selectedCompound.name : 'Kein Betrieb ausgewählt'}
                    </Button>
            ) : (
                <Button
                    endIcon={<ChangeCircleOutlinedIcon />}
                    variant="contained"
                    color="secondary"
                    sx={{ alignSelf: "center", font: "bubblegum", marginRight: "32px" }}
                    onClick={handleOpen}
                >
                    {selectedCompound ? selectedCompound.name : 'Kein Betrieb ausgewählt'}
                </Button>

            )}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Betrieb wechseln</DialogTitle>
                <DialogContent>
                    <Typography variant={"body1"}>Wähle einen Betrieb aus, den du verwalten möchtest:</Typography>
                    <FormControl>
                        <RadioGroup
                            name="compounds-radio-group">

                            {compounds.map((compound) => (
                                <FormControlLabel
                                    key={compound.compoundId}
                                    value={compound}
                                    control={<Radio color="secondary" />}
                                    checked={selectedValue === compound}
                                    label={getReadableCompoundType(compound) + " " + compound.name + (compound.hostId !== Number(hostId) ? " (Stellvertretung)" : "")}
                                    onChange={() => setSelectedValue(compound)} />
                            ))}
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleSave} color="secondary">
                        Speichern
                    </Button>
                    <Button onClick={handleClose}>Abbrechen</Button>
                </DialogActions>
            </Dialog>
        </>
        );
    } else {
        return null;
    }
}

export default CompoundSwitcher;
