import React from 'react';
import {ReactSVG} from 'react-svg';

const svgPaths : Record<string, string>  = {
    'timefast': '/assets/SVGIcons/time-fast-svgrepo-com.svg',
    'personcheck': '/assets/SVGIcons/person-check-fill-svgrepo-com.svg',
    'openinghours': '/assets/SVGIcons/opening-hours-svgrepo-com.svg',
    'route': '/assets/SVGIcons/route-svgrepo-com.svg',
    'camper': '/assets/SVGIcons/rv-truck.svg',
    'water': '/assets/SVGIcons/water-tap.svg',
    'campground': '/assets/SVGIcons/campground.svg',
    'swisstainable': '/assets/SVGIcons/swisstainable.svg',
    'community': '/assets/SVGIcons/community.svg',
    'neueorte': '/assets/SVGIcons/neueorte.svg',
    'earlyaccess': '/assets/SVGIcons/earlyAccess.svg',
};

type SVGComponentProp = {
    iconValue: string;
    size?: string | number;
}


const getIconPathByName = (iconName: string): string | null => {
    return svgPaths[iconName] || null;
};

const SVGComponent: React.FC<SVGComponentProp> = ({iconValue, size}) => {
    const iconPath = getIconPathByName(iconValue);

    return (
        <div>
            {iconPath && <ReactSVG src={iconPath} beforeInjection={(svg) => {
                if (size) {
                    svg.setAttribute('width', `${size}`)
                    svg.setAttribute('height', `${size}`)
                }
            }} />}
        </div>
    );
};

export default SVGComponent;
