import React, {useMemo} from 'react';
import {Alert, Box, Typography} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import Main from "../../layouts/Main";
import Button from "@mui/material/Button";
import {CancelablePromise, Subscription, SubscriptionService, User} from '../../services/restserver-openapi';
import {chargebeePromise} from "../../utils/chargebee";
import {getUserAsync, getUserEntitlementsAsync, getUserSubscriptionsAsync} from "../../store/navBar/userSlice";

interface SelfServicePortalProps {
    subscriptions: Subscription[];
}

export default function SelfServicePortal(props: SelfServicePortalProps) {
    const dispatch = useAppDispatch();
    const userRole = useAppSelector(state => state.user.userRole);
    const userSpecificationId = useAppSelector(state => state.user.userSpecificationId);
    const isVerifiedHost = useAppSelector(state => state.user.isVerifiedHost);

    const currentAbos = useMemo(() => {
        return props.subscriptions.flatMap(sub =>
            sub.subscriptionItems?.map(item => item.itemPriceId ? item.itemPriceId : '') ?? [],
        );
    }, [props.subscriptions]);

    const hasPaidSubscription = useMemo(() => {
        return currentAbos.length > 0 && !currentAbos.some(abo => abo.startsWith('host-free') || abo.startsWith('vanlifer-free'));
    }, [currentAbos]);

    if (userRole === User.userRole.HOST && !isVerifiedHost) {
        return (
            <Main>
                <Box>
                    <Alert severity="warning">
                        Verifizierung ausstehend. Deine Subscription kann nur geändert werden, wenn Du verifizierst bist.
                    </Alert>
                </Box>
            </Main>
        );
    }

    async function openSelfServicePortal() {
        const cbInstance = await chargebeePromise;

        if (!cbInstance) {
            console.error('Chargebee not loaded');
            return;
        }

        const SelfServicePromise : CancelablePromise<any> = SubscriptionService.getPortal();

        cbInstance.setPortalSession(() => {
            return SelfServicePromise;
        });

        let cbPortal = cbInstance.createChargebeePortal();
        cbPortal.open({
            close() {
                dispatch(getUserSubscriptionsAsync());
                dispatch(getUserAsync({userSpecificationId, userRoleInput: userRole!}));
                dispatch(getUserEntitlementsAsync());
                //close callbacks
            }
        });
    }

    if (!hasPaidSubscription) { // Button should only be visible if paid subscription exists.
        return (<Box />);
    }

    return (
        <Main>
            <Box>
                <Button size={'large'} variant={'contained'} onClick={() => openSelfServicePortal()}>
                    Mitgliedschaft anpassen
                </Button>
            </Box>
        </Main>
    );


}