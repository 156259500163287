import {Subscription} from "../services/restserver-openapi";

export function statusGerman(status?: string) {
    if (!status) {
        return status;
    }
    switch (status.toLowerCase()) {
        case "future":
            return "soon";
        case "active":
            return "active";
        case "in_trial":
            return "in trial";
        case "non_renewing":
            return "runout";
        case "paused":
            return "paused";
        case "cancelled":
            return "cancelled";
        case "transferred":
            return "transferred";
        default:
            return status;
    }
}

export function statusColor(status?: string) {
    if (!status) {
        return 'primary';
    }
    switch (status.toLowerCase()) {
        case "future":
            return "info";
        case "active":
            return "success";
        case "in_trial":
            return "warning";
        case "non_renewing":
            return "error";
        case "paused":
            return "info";
        case "cancelled":
            return "error";
        case "transferred":
            return "info";
        default:
            return 'primary';
    }
}

export function billingPeriodUnitGerman(billingPeriodUnit?: string) {
    if (!billingPeriodUnit) {
        return billingPeriodUnit;
    }
    switch (billingPeriodUnit.toLowerCase()) {
        case "day":
            return "Tag";
        case "week":
            return "Woche";
        case "month":
            return "Monat";
        case "year":
            return "Jahr";
        case "threemonth": // könnte anders heissen
            return "Saison (3 Monate)";
        default:
            return billingPeriodUnit;
    }
}

export function getTotalPriceWithoutCharges(subscription: Subscription) {
    const totalInCents = subscription.subscriptionItems?.reduce((acc, item) => {
        if (item.itemType !== "CHARGE") {
            return acc + item.amount!;
        }
        return acc;
    }, 0);
    return convertPrice(totalInCents!, subscription.currencyCode!)
}

export function convertPrice(amount: number, currency: string) {
    return (amount / 100).toFixed(2) + ' ' + currency;
}

export function getSubscriptionName(subscription: Subscription) {
    if (subscription.subscriptionItems?.some(item => item.itemPriceId?.startsWith('host-free'))) {
        return "Buchungsverwaltung FREE";
    }
    if (subscription.subscriptionItems?.some(item => item.itemPriceId?.startsWith('host-flat'))) {
        return "Buchungsverwaltung FLAT";
    }
    if (subscription.subscriptionItems?.some(item => item.itemPriceId?.startsWith('host-flex'))) {
        return "Buchungsverwaltung FLEX";
    }
    if (subscription.subscriptionItems?.some(item => item.itemPriceId?.startsWith('vanlifer-free'))) {
        return "Free";
    }
    if (subscription.subscriptionItems?.some(item => item.itemPriceId?.startsWith('vanlifer-premium-CHF-Monthly'))) {
        return "Flex Traveller";
    }
    if (subscription.subscriptionItems?.some(item => item.itemPriceId?.startsWith('vanlifer-premium-CHF-Every-3-months'))) {
        return "Season Pass";
    }
    if (subscription.subscriptionItems?.some(item => item.itemPriceId?.startsWith('vanlifer-premium-CHF-Yearly'))) {
        return "Year-round Pass";
    }
    if (subscription.subscriptionItems?.some(item => item.itemPriceId?.startsWith('vanlifer-early-access-CHF-Every-5-years'))) {
        return "Early Access";
    }
}

export function formatChargebeeDate(dateString: string) {
    const date = new Date(dateString);
    if (date.getTime() === new Date('1970-01-01').getTime()) {
        return '';
    } else {
        return date.toLocaleDateString();
    }
}

export function isHostSubscription(subscription: Subscription) {
    return subscription.subscriptionItems?.some(item => item.itemPriceId?.startsWith('host'));
}

export function isEarlyAccessSubscription(subscription: Subscription) {
    return subscription.subscriptionItems?.some(item => item.itemPriceId?.startsWith('vanlifer-early-access'));
}