import {Grid, Skeleton, Typography, useTheme} from '@mui/material';
import React, {useState} from 'react';
import {TourOverview} from "../../services/restserver-openapi";
import BookedTourCard from "./BookedTourCard";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import SyncIcon from '@mui/icons-material/Sync';

type BookingOverviewTableProp = {
    tours: TourOverview[];
}

export function BookingOverviewSkeleton() {
    return (
        <Grid container spacing={3} sx={{marginBottom: '24px'}}>
            {Array.from({length: 3}).map((_, index) => (
                <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                    <Skeleton variant="rounded" height={180} width="100%"/>
                </Grid>
            ))}
        </Grid>
    );
}

export default function BookingOverviewGridComponent(props: BookingOverviewTableProp) {
    const theme = useTheme();
    const [numberOfToursShown, setNumberOfToursShown] = useState<number>(7);

    const ShowMoreToursCard = (): React.ReactElement => (
        <Card elevation={0} sx={{cursor: 'pointer', height: '100%', border: `dashed #999999 2px`, backgroundColor: '#f5f5f5'}}
              onClick={() => setNumberOfToursShown(numberOfToursShown + 7)}>
            <CardContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', p: 0, flexDirection: 'column'}}>
                <SyncIcon sx={{width: '50px', height: '50px', mb: 1, color: `${theme.palette.primary.main}`}} />
                <Typography>Mehr laden</Typography>
            </CardContent>
        </Card>
    );

    return (
        <>
            <Grid container spacing={3} sx={{marginBottom: '24px'}}>
                {props.tours.slice(0, numberOfToursShown).map((tour, index) => (
                    <Grid item key={index} xs={12} sm={6} md={6} lg={4}>
                        <BookedTourCard tour={tour}/>
                    </Grid>
                ))}

                {numberOfToursShown < props.tours.length &&
                    <Grid item key="show-more-card" xs={12} sm={6} md={4} lg={3}>
                        <ShowMoreToursCard/>
                    </Grid>}
            </Grid>
        </>
    );
}
