import React, {ChangeEvent, useEffect, useState} from 'react';
import {Button, CircularProgress, Grid, MenuItem, TextField, Typography} from "@mui/material";
import {numberOfPeople} from "../../constants/constants";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {createSite, editSite, getTotalOwnedSites} from "../../store/Host/sitesManagement/siteSlice";
import {fetchAllSizes} from "../../store/Host/sitesManagement/sizesSlice";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {Site, Size} from "../../services/restserver-openapi";
import UploadPictures from "../presentationalComponents/UploadPictures";
import {Save} from "@mui/icons-material";
import {
    fetchExistingImages,
    handleImageDelete,
    handleImageUploadSite,
    handleUpdateImageChange
} from "../../utils/Host/offerImageUtil";
import {getReadableSiteType} from "../../utils/typeUtils";

type CreateSiteFormProps = {
    onBack: () => void
}

const SiteForm: React.FC<CreateSiteFormProps> = ({onBack}) => {
    const dispatch = useAppDispatch();
    const sizes = useAppSelector(state => state.sizes.sizes);
    const site = useAppSelector(state => state.site.site);
    const [selectedType, setSelectedType] = useState(site.siteType);
    const [siteData, setSiteData] = useState<Site>(site);
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [files, setFiles] = useState<string[]>([]);

    const [loadingSave, setLoadingSave] = useState(false);

    useEffect(() => {
        dispatch(fetchAllSizes());
    }, [dispatch]);

    useEffect(() => {
        setSiteData(site)
        setSelectedType(site.siteType);
    }, [dispatch, site]);

    const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();
        if (siteData.siteId) {
            setLoadingSave(true);
            await handleImageUploadSite(siteData,selectedFiles);
            await dispatch(editSite(siteData))
            setLoadingSave(false);
        } else {
            await dispatch(createSite(siteData)).then(async (response) => {
                if (createSite.fulfilled.match(response)) {
                    setSiteData(response.payload);
                    await handleImageUploadSite(response.payload, selectedFiles);
                }
            });
        }
        await dispatch(getTotalOwnedSites());
        onBack()
    }

    const features = [
        {key: "electricity", label: "Strom", checked: siteData.electricity},
        {key: "water", label: "Wasser", checked: siteData.water},
        {key: "petsAllowed", label: "Haustiere erlaubt", checked: siteData.petsAllowed},
        {key: "nonSmoking", label: "Nichtraucher", checked: siteData.nonSmoking},
        {key: "tvConnection", label: "TV/ Kabelanschluss", checked: siteData.tvConnection},
        {key: "cookingFacility", label: "Kochgelegenheit", checked: siteData.cookingFacility},
        {key: "minibar", label: "Minibar", checked: siteData.minibar},
        {key: "sharedBathroom", label: "WC zur Mitbenutzung", checked: siteData.sharedBathroom},
        {key: "sharedShower", label: "Dusche zur Mitbenutzung", checked: siteData.sharedShower},
        {key: "privateBathroom", label: "Eignes WC", checked: siteData.privateBathroom},
        {key: "privateShower", label: "Eigene Dusche", checked: siteData.privateShower},
        {key: "refrigerator", label: "Kühlschrank", checked: siteData.refrigerator},
        {key: "balconyOrTerrace", label: "Balkon/Terrasse", checked: siteData.balconyOrTerrace},
    ];

    const handleSiteFeaturesChange = (key: string, checked: boolean) => {
        setSiteData({
            ...siteData,
            [key]: checked,
        });
    };

    useEffect(() => {
        // Fetch existing images and prepopulate selectedFiles
        if (siteData.imageIds) {
            fetchExistingImages(siteData.imageIds, setSelectedFiles, selectedFiles);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [siteData.imageIds]);


    const handleUploadChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleUpdateImageChange(e, files, setFiles, selectedFiles, setSelectedFiles);
    }

    const handleUploadDelete = (index: number) => {
        handleImageDelete(index, files, setFiles, selectedFiles, setSelectedFiles);
    }

    const handleTypeChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const newType = event.target.value as Site.siteType;
        setSelectedType(newType);
        setSiteData({ ...siteData, siteType: newType });
    };

    const showSizeSelect = siteData.siteType === Site.siteType.ROOM || siteData.siteType === Site.siteType.PITCH;
    const sizeSelectRequired = siteData.siteType === Site.siteType.ROOM || siteData.siteType === Site.siteType.PITCH;

    let filteredSizes: Size[] = [];
    if (siteData.siteType === Site.siteType.PITCH) {
        filteredSizes = sizes.filter(size =>
            size.name === "Zelt" ||
            size.name === "Campervan" ||
            size.name === "PKW & Wohnwagen" ||
            size.name === "Gr. Wohnmobil (>7m)"
        );
    } else if (siteData.siteType === Site.siteType.ROOM) {
        filteredSizes = sizes.filter(size =>
            size.name === "Einzelzimmer" ||
            size.name === "Doppelzimmer" ||
            size.name === "Suite" ||
            size.name === "Junior-Suite"
        );
    }


    return (
        <form onSubmit={onSubmit}>
            <Grid container rowSpacing={2} columnSpacing={{xs: 1, sm: 2, md: 3}} justifyContent="space-between">
                <Grid item xs={12} container rowSpacing={2} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                <Grid item xs={8}>
                    <TextField
                        select
                        label="Typ"
                        fullWidth
                        value={selectedType}
                        onChange={handleTypeChange}
                        required={true}
                    >
                        {Object.keys(Site.siteType).map((type) => (
                            <MenuItem key={type} value={type}>
                                {getReadableSiteType({ siteType: Site.siteType[type as keyof typeof Site.siteType] })}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                {
                    showSizeSelect && (
                        <Grid item xs={4}>
                            <TextField
                                name="sizeId"
                                label="Grössenbezeichnung"
                                value={siteData.sizeId ? siteData.sizeId : ""}
                                select
                                fullWidth
                                onChange={(e) => setSiteData({...siteData, sizeId: +e.target.value})}
                                required={sizeSelectRequired}
                            >
                                {filteredSizes.map((size: Size) => (
                                    <MenuItem key={size.sizeId} value={size.sizeId}>{size.name}</MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    )
                }
                </Grid>

                <Grid item xs={6} sm={6} md={2}>
                    <TextField
                        required
                        name="label"
                        label="Platz/Zimmer Nr."
                        value={siteData.label}
                        fullWidth
                        onChange={(e) => setSiteData({...siteData, label: e.target.value})}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                    <TextField
                        required
                        name="offer.name"
                        label="Freie Bezeichnung"
                        value={siteData.offer?.name}
                        fullWidth
                        onChange={(e) => setSiteData({...siteData, offer: {...siteData.offer, name: e.target.value}})}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                    <TextField
                        required
                        name="site.area"
                        type={"number"}
                        label="Fläche in m²"
                        value={siteData.area}
                        fullWidth
                        onChange={(e) => setSiteData({...siteData, area: +e.target.value})}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <TextField name="site.minimum_occupancy" label='Min. Belegunsanzahl'
                               value={siteData.minimumOccupancy ? siteData.minimumOccupancy : ""} select fullWidth
                               onChange={(e) => setSiteData({
                                   ...siteData,
                                   minimumOccupancy: e.target.value ? +e.target.value : undefined
                               })}>
                        {numberOfPeople.map((nrPeople: number) =>
                            (<MenuItem key={nrPeople} value={nrPeople}>{String(nrPeople)}</MenuItem>))
                        }
                    </TextField>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <TextField name="site.maximum_occupancy" label='Max. Belegunsanzahl'
                               value={siteData.maximumOccupancy ? siteData.maximumOccupancy : ""} select fullWidth
                               onChange={(e) => setSiteData({
                                   ...siteData,
                                   maximumOccupancy: e.target.value ? +e.target.value : undefined
                               })}>
                        {numberOfPeople.map((nrPeople: number) =>
                            (<MenuItem key={nrPeople} value={nrPeople}>{String(nrPeople)}</MenuItem>))
                        }
                    </TextField>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                    <TextField
                        label="Beschreibung"
                        multiline
                        rows={4}
                        value={siteData.offer?.description}
                        onChange={(e) => setSiteData({
                            ...siteData,
                            offer: {...siteData.offer, description: e.target.value}
                        })}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                        {features.map((feature) => (
                            <FormControlLabel
                                key={feature.key}
                                control={
                                    <Checkbox
                                        checked={feature.checked}
                                        onChange={(e) => handleSiteFeaturesChange(feature.key, e.target.checked)}
                                    />
                                }
                                label={feature.label}
                            />
                        ))}
                </Grid>
                <Grid item xs={12} md={12}>
                    <Typography variant="h5">Bilder</Typography>
                    <UploadPictures handleChange={handleUploadChange} selectedFiles={selectedFiles} handleDelete={handleUploadDelete} />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <TextField
                        label={"Gästeinformationen zum " + getReadableSiteType(siteData)}
                        multiline
                        rows={4}
                        value={siteData.guestInformation}
                        onChange={(e) => setSiteData({
                            ...siteData,
                            offer: {...siteData.offer},
                            guestInformation: e.target.value
                        })}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={4} sm={1} md={1}>
                    <Button variant="contained" color="primary" type="submit" endIcon={loadingSave ? <CircularProgress /> : <Save  />}> Speichern </Button>
                </Grid>
            </Grid>
        </form>
    );
}

export default SiteForm;
