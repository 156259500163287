/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Coordinates } from './Coordinates';
import type { LocalTime } from './LocalTime';
export type StayOverview = {
    stayId?: number;
    coordinates?: Coordinates;
    siteId?: number;
    fromDate?: number;
    toDate?: number;
    compoundType?: StayOverview.compoundType;
    arrivalTime?: LocalTime;
    comment?: string;
};
export namespace StayOverview {
    export enum compoundType {
        HOTEL = 'HOTEL',
        GUESTHOUSE = 'GUESTHOUSE',
        CAMPSITE = 'CAMPSITE',
        FARM = 'FARM',
        PRIVATE_ACCOMMODATION = 'PRIVATE_ACCOMMODATION',
        RV_PITCH = 'RV_PITCH',
        PARKING_LOT = 'PARKING_LOT',
        OTHER = 'OTHER',
    }
}

