import React from 'react';
import {Typography} from "@mui/material";
import moment from "moment";
import {useAppSelector} from "../../hooks/hooks";

function TourDetailsSummary() {
    const choosedTourDetail = useAppSelector(state => state.tour.choosedTourDetail);
    const detailedTourDistances = useAppSelector(state => state.tour.detailedTourDistances);
    let price = choosedTourDetail.price ? choosedTourDetail.price.toFixed(2) + " CHF" : 'Keine Information';

    const oldestFromDate = choosedTourDetail.stays && choosedTourDetail.stays.length > 0
        ? choosedTourDetail.stays.reduce((oldest, stay) => oldest.fromDate! < stay.fromDate! ? oldest : stay).fromDate
        : null;

    const newestToDate = choosedTourDetail.stays && choosedTourDetail.stays.length > 0
        ? choosedTourDetail.stays.reduce((newest, stay) => newest.toDate! > stay.toDate! ? newest : stay).toDate
        : null;


    return (<React.Fragment>
            {choosedTourDetail.stays?.length && <React.Fragment>
                    <Typography variant={'h6'}>
                        Deine Reise im Überblick:
                    </Typography>
                    <Typography variant={'body1'}>
                        Reisezeit: {moment(oldestFromDate).format('DD.MM.YYYY')}
                        {' - '}
                        {moment(newestToDate).format('DD.MM.YYYY')}<br/>
                        Startort: {choosedTourDetail.stays[0].stayCompound?.city}<br/>
                        Anzahl Campingplätze: {choosedTourDetail.stays?.length}<br/>
                        Gesamtkosten: {price} (Vor Ort zu zahlen)<br />


                        Tourlänge: {detailedTourDistances?.reduce((accumulator, currentValue) => accumulator + currentValue, 0)} km
                    </Typography>
                </React.Fragment>
                }
    </React.Fragment>
    );
}

export default TourDetailsSummary;