import React from 'react';

const BetaBadge: React.FC = () => {
    const badgeStyle: React.CSSProperties = {
        display: 'inline-block',
        padding: '2px 4px',
        marginRight: '10px',
        marginLeft: '10px',
        fontSize: '10px',
        color: 'white',
        backgroundColor: '#f39c12',
        borderRadius: '5px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    };

    return <span style={badgeStyle}>Beta</span>;
};

export default BetaBadge;