import React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import imgRouteProposal from '../../assets/Images/Screenshot_Reiseplanung.jpg';
import {PaletteMode} from "@mui/material";
import {Link} from "react-router-dom";

const SchnelleReise = (): JSX.Element => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
      <Grid container spacing={4}>
        <Grid item container xs={12} md={6} alignItems={'center'}>
          <Box>
            <Box marginBottom={2}>
              <Typography
                variant="h3"
                color="text.primary"
                sx={{ fontWeight: 700 }}
              >
                Schnell und einfach zur
                <br />
                <Typography
                  color={'text.primary'}
                  component={'span'}
                  variant={'inherit'}
                  sx={{
                    background: `linear-gradient(180deg, transparent 82%, ${alpha(
                        mode === 'light' as PaletteMode ? theme.palette.secondary.main : theme.palette.primary.main,
                        0.3,
                    )} 0%)`,
                  }}
                >
                  kompletten Reise
                </Typography>
              </Typography>
            </Box>
            <Box marginBottom={3}>
              <Typography variant="h6" component="p" color="text.secondary">
                Erhalte Vorschläge für Dein persönliches Abenteuer und gestalte Deine individuelle Reise - vollgepackt mit atemberaubenden Erlebnissen!
                Langweilige Reiseplanung war gestern!
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'stretched', sm: 'flex-start' }}
            >
              <Button
                variant="contained"
                color="primary"
                size="large"
                component={Link}
                to={"/login"}
                fullWidth={isMd ? false : true}
              >
                Jetzt kostenlos starten...
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          container
          alignItems={'center'}
          justifyContent={'center'}
          xs={12}
          md={6}
        >
          <Box
            component={'img'}
            height={1}
            width={1}
            src={imgRouteProposal.toString()}
            alt="..."
            borderRadius={2}
            maxWidth={600}
            maxHeight={500}
            sx={{
              objectFit: 'cover',
              boxShadow: '19px 20px 0px 0 rgb(140 152 164 / 13%)',
              filter:
                theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
            }}
          />
        </Grid>
      </Grid>
  );
};

export default SchnelleReise;
