import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import {newsfeed} from "../../../constants/constants";

const NewsFeedComponent = () => {
    const [newsContent, setNewsContent] = useState('');

    // useEffect(() => {
    //     fetch('./feed/news.md')
    //         .then((response) => response.text())
    //         .then((text) => setNewsContent(text));
    // }, []);

    useEffect(() => {
        fetch(newsfeed)
            .then((response) => response.text())
            .then((text) => {
                console.log('Markdown-Inhalt:', text); // Debugging
                setNewsContent(text);
            })
            .catch((error) => console.error('Fehler beim Laden der Markdown-Datei:', error));
    }, []);

    return (
        <div style={{ padding: '10px', maxWidth: '800px', margin: '0 auto' }}>
            <ReactMarkdown>{newsContent}</ReactMarkdown>
        </div>
    );
};

export default NewsFeedComponent;
