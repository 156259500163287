import {Compound, Site} from "../services/restserver-openapi";

export function getReadableCompoundType(compound: Compound): string {
    switch (compound.compoundType) {
        case Compound.compoundType.HOTEL:
            return "Hotel";
        case Compound.compoundType.GUESTHOUSE:
            return "Pension";
        case Compound.compoundType.CAMPSITE:
            return "Campingplatz";
        case Compound.compoundType.FARM:
            return "Bauernhof";
        case Compound.compoundType.PRIVATE_ACCOMMODATION:
            return "Privatunterkunft";
        case Compound.compoundType.RV_PITCH:
            return "WoMo-Stellplatz";
        case Compound.compoundType.PARKING_LOT:
            return "Parkplatz";
        case Compound.compoundType.OTHER:
            return "Andere";
        default:
            return "Unbekannt"
    }
}

export function getReadableSiteType(site: Site): string {
    switch (site.siteType) {
        case Site.siteType.ROOM:
            return "Zimmer";
        case Site.siteType.APARTMENT:
            return "Ferienwohnung";
        case Site.siteType.PITCH:
            return "Stellplatz";
        case Site.siteType.MOBILEHOME:
            return "Mobilhome";
        case Site.siteType.SITEOTHER:
            return "Andere";
        default:
            return "Unbekannt";
    }
}