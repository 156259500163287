import * as React from 'react';
import {Box, Grid, Typography, useTheme} from "@mui/material";
import {Link} from 'react-router-dom';
import {urlAGBPDF, urlPrivacyPDF} from "../constants/constants";
import ImpressumModal from "./presentationalComponents/ImpressumModal";

export default function Footer() {
    const theme = useTheme();

    return (
        <footer id={'footer'} style={{
            backgroundColor: theme.palette.primary.main,
            left: "0",
            bottom: "0",
            height: "2.5rem",
            position: "absolute",
            width: "100%"
        }}>
            <Grid container
                display='flex-start'
                flexDirection='row'
                rowSpacing={2}
                columnSpacing={{ xs: 2, sm: 2, md: 2, xl: 2 }}
                justifyContent="start"
                alignItems="center"
                style={{
                    backgroundColor: theme.palette.primary.main,
                    left: "0",
                    bottom: "0",
                    paddingLeft: "1rem",
                }}>
                <Grid item>
                    <a href={urlAGBPDF} target="_blank" rel="noreferrer" style={{textDecoration:'none'}}>
                        <Typography variant="body1" color="secondary" fontFamily={'bubblegum-sans'}>AGB's</Typography>
                    </a>
                </Grid>
                <Grid item>
                    <a href={urlPrivacyPDF} target="_blank" rel="noreferrer" style={{textDecoration:'none'}}>
                        <Typography variant="body1" color="secondary" fontFamily={'bubblegum-sans'}>Datenschutzerklärung</Typography>
                    </a>
                </Grid>
                <Grid item>
                    <ImpressumModal/>
                </Grid>
                <Grid item>
                    <Link to={'/profil/support'} style={{textDecoration:'none'}}>
                        <Typography variant="body1" color="secondary" fontFamily={'bubblegum-sans'}>Hilfe</Typography>
                    </Link>
                </Grid>
                <Grid item sx={{ marginLeft: 'auto', marginRight: '1rem'}}>
                    <Box display="flex" alignItems="center">
                    <Typography
                        variant={"body1"}
                        color={"secondary"}
                    >
                        &copy;&nbsp;
                    </Typography>
                    <Typography variant={"subtitle2"} color={"secondary"} fontFamily={'bubblegum-sans'}>
                        {new Date().getFullYear()} Vanlife Travel
                    </Typography>
                    </Box>
                </Grid>
            </Grid>
        </footer>
    );
}
