/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Person } from './Person';
export type User = {
    userId?: number;
    userErpId?: number;
    userCrmId?: number;
    chargebeeId?: string;
    person?: Person;
    pictureId?: number;
    userRole?: User.userRole;
    communityName?: string;
};
export namespace User {
    export enum userRole {
        HOST = 'HOST',
        AGENT = 'AGENT',
        VANLIFER = 'VANLIFER',
    }
}

