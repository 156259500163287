import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';

import Main from '../layouts/Main';
import Container from '../components/Container';
import LoginForm from "../components/registerComponents/LoginForm";
import LoginImage from '../assets/Images/unused/dino-reichmuth-A5rCN8626Ck-unsplash.jpg';

const SigninSimple = (): JSX.Element => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const LoginPicture = () => {
    return (
      <Box
        sx={{
          height: { xs: 'auto', md: 1 },
          '& .slick-slide img': {
            objectFit: 'cover',
          },
          '& .slick-list, & .slick-slider, & .slick-track, & .slick-slide > div': {
            height: { xs: 'auto', md: 1 },
          },
        }}
      >
        <Box
          component={'img'}
          loading="lazy"
          src={LoginImage}
          height={{ xs: 'auto', md: 1 }}
          maxHeight={{ xs: 500, md: 1 }}
          style={{objectFit: 'cover'}}
          width={1}
          maxWidth={1}
        />
      </Box>
    );
  };

  return (
    <Main>
      <Box bgcolor={theme.palette.alternate} height={1} width={1} >
        <Box
          sx={{
            width: 1,
            height: 1,
            overflow: 'hidden',
          }}
        >
          <Container paddingX={0} paddingY={0} maxWidth={{ sm: 1, md: 1236 }}>
            <Box
              display={'flex'}
              flexDirection={{ xs: 'column', md: 'row' }}
              position={'relative'}
              minHeight={{ xs: 'auto', md: 'calc(100vh - 58px)' }}
            >
              <Box
                sx={{
                  flex: { xs: '0 0 100%', md: '0 0 50%' },
                  position: 'relative',
                  maxWidth: { xs: '100%', md: '70%' },
                  order: { xs: 1, md: 2 },
                }}
              >
                <Box
                  sx={{
                    // Position im Login Bild
                    width: { xs: 1, md: '70vw' },
                    height: '100%',
                    position: 'relative',
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                      overflow: 'hidden',
                    }}
                  >
                    <Box
                      sx={{
                        overflow: 'hidden',
                        left: '0%',
                        width: 1,
                        height: 1,
                        position: { xs: 'relative', md: 'absolute' },
                        clipPath: {
                          xs: 'none',
                          md: 'polygon(10% 0%, 100% 0, 100% 100%, 10% 100%, 0% 100%)',
                        },
                        shapeOutside: {
                          xs: 'none',
                          md: 'polygon(10% 0%, 100% 0, 100% 100%, 0% 100%, 0% 100%)',
                        },
                      }}
                    >
                      <LoginPicture />
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                width={1}
                order={{ xs: 2, md: 1 }}
                display={'flex'}
                alignItems={'center'}
              >
                <Container>
                  <LoginForm />
                </Container>
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>

    </Main>
  );
};

export default SigninSimple;
