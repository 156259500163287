import React, {FormEventHandler, useMemo, useRef, useState} from 'react';
import {Alert, Box, Button, Checkbox, FormControl, FormControlLabel, Grid, Link, Typography} from '@mui/material';
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import {setBookedComponent, setEditComponent} from "../../store/Vanlifer/vanliferComponentsSlice";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {asyncPostBookTour} from "../../store/Vanlifer/tourSlice";
import {tourEditorText, welcomeRegisterPage} from "../../constants/textConstant";
import {postPersonPreferencesAsync} from "../../store/navBar/userSlice";
import BookingTourOverviewComponent from "./BookingTourOverview";
import {asyncCheckKudos} from "../../store/Vanlifer/Gamification/KudosSlice";
import ScrollToBottomFab from "./ScrollToBottomFab";
import {urlAGBPDF} from "../../constants/constants";
import {Link as RouterLink} from "react-router-dom";

function TourEditor() {
    const dispatch = useAppDispatch();
    const choosedTourDetail = useAppSelector(state => state.tour.choosedTourDetail);
    const preferencesFromStore = useAppSelector(state => state.user.preferences);
    const [agbAccepted, setAgbAccepted] = useState(preferencesFromStore.acceptedAgb);
    let userId = useAppSelector(state => state.login.userId);
    const kudosLastCheckedAt = useAppSelector(state => state.kudos.kudosLastCheckedAt);
    const loading = useAppSelector(state => state.tour.loading);
    const backButtonRef = useRef<HTMLButtonElement>(null);
    const userEntitlements = useAppSelector(state => state.user.entitlements);
    const canBookTour = useMemo(() => {
        return userEntitlements.some(entitlement => entitlement.featureId === 'vanliferbooktour' && entitlement.value === "true")
    }, [userEntitlements]);

    function onBackClick() {
        dispatch(setEditComponent(false));
    }

    function editAGB() {
        if (userId) {
            dispatch(postPersonPreferencesAsync({
                userId: userId,
                preferences: {...preferencesFromStore, acceptedAgb: agbAccepted}
            }))
        }
    }

    const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();
        editAGB();
        await dispatch(asyncPostBookTour(choosedTourDetail.tourId!));
        dispatch(setEditComponent(false));
        dispatch(setBookedComponent(true));

        if (userId) {
            dispatch(asyncCheckKudos({checkKudosSinceDate: kudosLastCheckedAt}));
        }
    }

    const handleAGBChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAgbAccepted(event.target.checked)
    };

    if (!canBookTour) {
        return (
            <Grid container display="flex" justifyContent="center">
                <Typography variant="h4">Tour Editor</Typography>
                <Typography variant={"body1"}>{tourEditorText.textForTourEdotor}</Typography>
                <Alert severity="error" action={
                    <Button component={RouterLink} color="inherit" size="small" to="/profil/abos">Kostenpflichtiges Abo abschliessen</Button>
                }
                >Du hast keine Berechtigung, Touren zu buchen. Dazu wird ein Abo benötigt.</Alert>
            </Grid>
        );
    }

    return (
        <Grid container display="flex" justifyContent="center">
            <ScrollToBottomFab scrollToElementRef={backButtonRef} />
            <Grid container><Grid item md={12} my={4} mx={2}>
                <Typography variant="h4">{choosedTourDetail.name}</Typography>
                <Typography variant={"body1"}>{tourEditorText.textForTourEdotor}</Typography>
            </Grid>
                <BookingTourOverviewComponent disableEditing={false}></BookingTourOverviewComponent>
                <Grid container display={"flex"} mx={1}>
                    <Grid item xs={12} sm={12} md={6.8} xl={6.8}
                          sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <form style={{display: "flex", flexDirection: "column", width: "100%"}} onSubmit={handleSubmit}>
                            <FormControl>
                                <FormControlLabel control={
                                    <Checkbox checked={agbAccepted} name="acceptedAgb" onChange={handleAGBChange} required/>}
                                                  label={<Link color="primary" href={urlAGBPDF} target="_blank" rel="noreferrer">
                                                      <Typography variant="body1" sx={{fontWeight: 'bold'}}>{welcomeRegisterPage.formControlAgb} *</Typography>
                                                  </Link>}/>
                            </FormControl>
                            <Grid my={4} display={"flex"} justifyContent={"space-between"}>
                                <Box><Button ref={backButtonRef} fullWidth variant="outlined" type="submit" color="primary"
                                             onClick={onBackClick}><ArrowBackIosNewRoundedIcon/>Zurück</Button></Box>
                                <Box><Button fullWidth variant="contained" type="submit" color="primary" disabled={loading}>Tour Buchen</Button></Box>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default TourEditor;
