import {
  Box,
  Button,
  Dialog, DialogActions, DialogContent, DialogTitle,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  Stack,
  useTheme,
} from '@mui/material';
import { Link } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import IconButton from '@mui/material/IconButton';
import SiteForm from '../../components/hostComponents/SiteForm';
import Container from '@mui/material/Container';
import SiteInformation from '../../components/hostComponents/SiteInformation';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import ErrorHandling from '../../components/presentationalComponents/ErrorHandling';
import ManagementCalendar from '../../components/hostComponents/ManagementCalendar';
import ManagementCalendarOverview from '../../components/hostComponents/ManagementCalendarOverview';
import LoadingPage from '../../components/presentationalComponents/LoadingPage';
import {
  defaultSite,
  deleteSelectedSite,
  fetchAllSites, getTotalOwnedSites,
  resetSiteErrorState,
  setSite,
} from '../../store/Host/sitesManagement/siteSlice';
import FormControl from '@mui/material/FormControl';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AvailabilityForm from '../../components/hostComponents/AvailabilityForm';
import { fetchAllSizes } from '../../store/Host/sitesManagement/sizesSlice';
import {
  fetchAllAvailabilities,
  resetAvailabilitiesErrorState,
} from '../../store/Host/sitesManagement/availabilitySlice';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Tooltip from '@mui/material/Tooltip';
import DialogContentText from '@mui/material/DialogContentText';
import {getReadableSiteType} from "../../utils/typeUtils";

function SitesManagement() {
  const theme = useTheme();
  const sites = useAppSelector(state => state.site.sites);
  const availabilities = useAppSelector(state => state.availability.availabilities);
  const errorSiteState = useAppSelector(state => state.site.error);
  const errorAvailabilityState = useAppSelector(state => state.availability.error);
  const site = useAppSelector(state => state.site.site);
  const [showSiteForm, setShowSiteForm] = useState(false);
  const dispatch = useAppDispatch();
  const [isCreatingSite, setIsCreatingSite] = useState(false);
  const loading = useAppSelector(state => state.site.loading);
  const hostId = useAppSelector(state => state.login.userSpecificationId);
  const selectedCompound = useAppSelector(state => state.compounds.selectedCompound);
  const compoundId = useAppSelector(state => state.compounds.selectedCompound?.compoundId);
  const [overview, setOverview] = useState(false);
  const compoundBelongsToHost = Number(selectedCompound?.hostId) === Number(hostId);
  const totalOwnedSites = useAppSelector(state => state.site.totalOwnedSites);
  const userEntitlements = useAppSelector(state => state.user.entitlements);
  const canAddMoreSites = useMemo(() => {
    const numberOfAllowedSites = userEntitlements.find(entitlement => entitlement.featureId === 'hostownednumberofsites')?.value;
    return numberOfAllowedSites === 'Unlimited' || totalOwnedSites < Number(numberOfAllowedSites);
  }, [userEntitlements, totalOwnedSites]);

  useEffect(() => {
    if (compoundId) {
      dispatch(fetchAllSites(compoundId)).unwrap().then((payload) => {
        if (payload) {
          dispatch(setSite(payload[0]));
          dispatch(fetchAllSizes());
          dispatch(getTotalOwnedSites());
        }
      });
    }
  }, [dispatch, compoundId]);

  useEffect(() => {
    dispatch(resetSiteErrorState());
    dispatch(resetAvailabilitiesErrorState());
  }, [dispatch]);

  useEffect(() => {
    if (site?.offer?.offerId) {
      dispatch(fetchAllAvailabilities(site.offer.offerId));
    }
  }, [dispatch, site]);

  const handleAddSite = async () => {
    setIsCreatingSite(true);
    if (compoundId) {
      dispatch(setSite({ compoundId: compoundId, ...defaultSite, offer: { ...defaultSite.offer, hostId: hostId } }));
    }
    setShowSiteForm(true);
  };

  const handleEditSite = () => {
    setShowSiteForm((prev) => !prev);
  };

  const handleDeleteSite = async () => {

    if (site?.siteId && compoundId) {
      await dispatch(deleteSelectedSite(site.siteId));
      await dispatch(fetchAllSites(compoundId)).unwrap().then((payload) => {
        if (payload) {
          dispatch(setSite(payload[0]));
        }
      });
      await dispatch(getTotalOwnedSites());
    }
    handleCloseDeleteDialog();
  };

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const DeleteDialog = () => {
    return (
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
      >
        <DialogTitle>
          {'Unterkunft löschen?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Möchtest du die Unterkunft "XYZ" wirklich löschen? Alle Daten werden vollständig gelöscht und diese Aktion
            kann nicht rückgängig gemacht werden.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} variant="outlined">
            Abbrechen
          </Button>
          <Button onClick={handleDeleteSite} color="primary" autoFocus variant="contained">
            Löschen
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleSiteSelection = (event: SelectChangeEvent<number | undefined>) => {
    if (showSiteForm) {
      setShowSiteForm(false);
    }
    const id = event.target.value;
    if (id) {
      const found = sites.find((item) => item.siteId === +id);
      if (found) {
        dispatch(setSite(found));
      }
    }
  };

  const onBack = async () => {
    setShowSiteForm((prev) => !prev);
    setIsCreatingSite(false);
    if (compoundId) {
      await dispatch(fetchAllSites(compoundId)).unwrap().then((payload) => {
        if (payload && (!site || !payload.some(item => item.siteId === site.siteId))) {
          dispatch(setSite(payload[0]));
        }
      });
    }
  };

  if (!compoundId) {
    return (
      <Box sx={{ minHeight: '100vh', backgroundColor: theme.palette.alternate.dark }} padding={2}>
        <Paper sx={{ backgroundColor: theme.palette.alternate.main, padding: '20px' }}>
          <Alert severity="warning">
            <AlertTitle>Kein Campingplatz ausgewählt</AlertTitle>
            <p>Es wurde kein Campingplatz ausgewählt.</p>
            <p>Sie benötigen Zugriff auf einen Campingplatz, den Sie oben links auswählen können. Falls der Button
              fehlt, haben Sie keine Rechte für den Zugriff auf einen Campingplatz. Wenn Sie die erforderlichen Rechte
              besitzen, müssen Sie einen neuen Campingplatz auf der <Link
                to={'/profil/spezial'}>Einstellungsseite</Link> erstellen.</p>
          </Alert>
        </Paper>
      </Box>

    );
  }

  return (
    <>
      {loading
        ? <div style={{ minHeight: '100vh', backgroundColor: theme.palette.alternate.main }}><LoadingPage /></div>
        :
        overview ?
          <Box sx={{ backgroundColor: theme.palette.alternate.dark }}>
            <Container sx={{ minHeight: '100vh', backgroundColor: theme.palette.alternate.dark }}>
              <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }} justifyContent="space-between"
                    sx={{ backgroundColor: theme.palette.alternate.main }} padding={4}>
                <Grid item xs={12} sm={12} md={12}>
                  <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Grid item xs={10}>
                      <Typography variant="h4">Übersichtskalender Campingplatz {selectedCompound.name}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{ width: '150px', float: 'left', marginBottom: '60px' }}
                        onClick={() => setOverview(false)}>
                        Zurück
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <ManagementCalendarOverview availabilities={availabilities} />
                </Grid>
              </Grid>
            </Container>
          </Box>
          :
          <Box sx={{ backgroundColor: theme.palette.alternate.dark }}>
            {(errorSiteState || errorAvailabilityState)
              ? <ErrorHandling errorMessage={errorSiteState ? errorSiteState : errorAvailabilityState} />
              : <Container sx={{
                minHeight: '100vh',
                backgroundColor: theme.palette.alternate.main,
              }}> {/** kleiner Strefen rechts neben dem Kalender */}
                {sites.length !== 0
                  ?
                  <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }} justifyContent="space-between"
                        sx={{ backgroundColor: theme.palette.alternate.main }}>
                    {site && site.siteId ?
                      <Grid item xs={12} sm={12} md={12}>
                        <Grid container my={3} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Grid item xs={6} sm={4} md={2}>
                            <Button
                              variant="contained"
                              color="primary"
                              sx={{ width: '150px', float: 'left', marginBottom: '60px' }}
                              onClick={() => setOverview(true)}
                            >
                              Übersicht
                            </Button>
                          </Grid>
                          <Grid item xs={6} sm={4} md={2}>
                            <FormControl sx={{ display: 'flex' }}>
                              <InputLabel id="select-site">Angebot auswählen</InputLabel>
                              <Select labelId="select-site" id="select-site"
                                      label="Angebot auswählen"
                                      value={site.siteId ? site.siteId : +''}
                                      onChange={handleSiteSelection}>
                                {
                                  sites.map((item) => (
                                    <MenuItem key={item.siteId}
                                              value={item.siteId}>{item.label}</MenuItem>))
                                }
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item>
                            {!showSiteForm &&
                              <Grid item>
                                <Tooltip
                                  title={!compoundBelongsToHost ? 'Sie haben keine Berechtigung für Betriebe mit Stellvertretungsrechte weitere Angebote hinzuzufügen' : (!canAddMoreSites ? 'Sie haben bereits die maximale Anzahl Angebote hinzugefügt. Für weitere Angebote benötigen Sie ein AddOn' : '')}>
                                  <Stack>

                                    <IconButton
                                      color="primary"
                                      aria-label="add new site"
                                      onClick={handleAddSite}
                                      disabled={!compoundBelongsToHost || !canAddMoreSites}
                                    >
                                      <Typography variant="body2">Angebot hinzufügen</Typography>
                                      <AddCircleIcon sx={{ fontSize: 40 }} />

                                    </IconButton>
                                  </Stack>
                                </Tooltip>
                              </Grid>}
                          </Grid>
                        </Grid>
                      </Grid>

                      : <Grid item xs={12} sm={12} md={4}> <Typography variant="h4"> Neues Angebot
                        erstellen</Typography> </Grid>
                    }
                    {(!isCreatingSite &&
                      <Grid item xs={12}>
                        <Typography variant="h5" style={{ marginBottom: '32px' }}>Verfügbarkeiten für den gewählten
                           {" " + getReadableSiteType(site)}</Typography>
                        <AvailabilityForm availabilities={availabilities} />
                        <ManagementCalendar availabilities={availabilities} />
                      </Grid>)}
                    <Grid my={3} item xs={12} sm={6} md={6}><Typography variant="h5">Informationen zum gewählten
                       {" " + getReadableSiteType(site)}</Typography></Grid>
                    {!showSiteForm ? (
                        <Grid item xs={12} sm={6} md={6}>
                          <Grid container justifyContent="flex-end" alignItems="center">
                            <Grid item>
                              <Tooltip
                                title={!compoundBelongsToHost ? 'Sie haben keine Berechtigung für Campingplätze mit Stellvertretungsrechte Stellplätze zu bearbeiten' : ''}>
                                                        <span><IconButton color="primary" aria-label="add edit site"
                                                                          onClick={handleEditSite}
                                                                          disabled={!compoundBelongsToHost}>
                                                            <EditIcon sx={{ fontSize: 30 }} />
                                                        </IconButton></span>
                              </Tooltip>
                            </Grid>

                            <Grid item>
                              <Tooltip
                                title={!compoundBelongsToHost ? 'Sie haben keine Berechtigung für Campingplätze mit Stellvertretungsrechte Stellplätze zu löschen' : ''}>
                                                        <span><IconButton color="primary" aria-label="delete site"
                                                                          disabled={!compoundBelongsToHost}
                                                                          onClick={handleOpenDeleteDialog}>
                                                            <DeleteIcon sx={{ fontSize: 30 }} />
                                                        </IconButton></span>
                              </Tooltip>
                              <DeleteDialog />
                            </Grid>
                          </Grid>
                        </Grid>

                      )
                      : (
                        <Grid item xs={2} md={1}>
                          <IconButton color="primary" aria-label="close form" onClick={onBack}>
                            <CancelIcon sx={{ fontSize: 30 }} />
                          </IconButton>
                        </Grid>
                      )}
                    <Grid my={3} item xs={12} sm={12} md={12}>
                      {showSiteForm ? (<SiteForm onBack={onBack} />) : (<SiteInformation />)}
                    </Grid>

                  </Grid>
                  :
                  <Grid container my={3} rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={6} md={10}>
                      <Typography variant="h6">Keine Angebote vorhanden. Erstelle deinen ersten
                        Angebot!</Typography>
                    </Grid>
                    <Grid item xs={2} md={1}>
                      <IconButton color="primary" aria-label="add new site" onClick={handleAddSite}>
                        <AddCircleIcon sx={{ fontSize: 40 }} />
                      </IconButton>
                    </Grid>
                    <Grid item xs={14}>
                      {(showSiteForm && <SiteForm onBack={onBack} />)}
                    </Grid>
                  </Grid>

                }
              </Container>
            }</Box>
      }
    </>
  );
}

export default SitesManagement;
