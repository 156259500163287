import React, {FormEventHandler, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../hooks/hooks';
import {
    getPersonPreferencesAsync,
    getUserAsync,
    putUserAsync,
} from '../../store/navBar/userSlice';
import {
    Box,
    Button,
    FormControl,
    Grid,
    InputLabel, Link,
    MenuItem,
    Select,
    TextField
} from '@mui/material';
import Typography from "@mui/material/Typography";
import {countryCodes as countries, countryCodes} from "../../constants/country_code";
import {User, Address, Person} from "../../services/restserver-openapi";
import Page from './Page/Page';
import { Main } from '../../layouts';
import Divider from "@mui/material/Divider";
import {urlPrivacyPDF} from "../../constants/constants";
import DatePickerVanlife from "../../components/presentationalComponents/DatePickerVanlife";
import moment from "moment/moment";

function PersonalDataEditor() {
    const dispatch = useAppDispatch();
    const personId = useAppSelector((state) => state.user.user.person?.personId);
    const person = useAppSelector((state) => state.user.user.person);
    const userId = useAppSelector(state => state.login.userId);
    const communityName = useAppSelector(state => state.user.user.communityName);
    const userRoleLogin = useAppSelector((state) => state.login.userRoleLogin);
    const specificationUserId = useAppSelector(state => state.login.userSpecificationId);
    const [localCountrySelected, setLocalCountrySelected] = useState<string>(
        countryCodes.find((country) => country.countryCode === person?.address?.country)?.countryName ?? 'Schweiz'
    );
    const isVerifiedHost = useAppSelector(state => state.user.isVerifiedHost);
    const isVerifiedAgent = useAppSelector(state => state.user.isVerifiedAgent);

    const [localUserData, setLocalUserData] = useState<User>({
        "userId": userId,
        "person": {
            "personId": personId,
            "firstname": person!.firstname,
            "lastname": person!.lastname,
            "email": person!.email,
            "phone": person!.phone,
            "birthday": person!.birthday,
            "gender": person!.gender,
            "address": {
                "company": person!.address!.company,
                "line1": person!.address!.line1,
                "line2": person!.address!.line2,
                "zipCode": person!.address!.zipCode,
                "city": person!.address!.city,
                "country": person!.address!.country,
                "state": person!.address!.state,
            }
        },
        "communityName": communityName,
        "userRole": userRoleLogin,
    });

    const profileStyling = {
        textfield: {
            width: '100%',
        },
    };

    const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();

        let verified = false;
        if (userRoleLogin === User.userRole.HOST) {
            verified = isVerifiedHost;
        } else if (userRoleLogin === User.userRole.AGENT) {
            verified = isVerifiedAgent;
        }
        if (personId)
        dispatch(putUserAsync({ inputUser: localUserData!, specificationUserId: specificationUserId!, personId: personId!, userRole: userRoleLogin!, verified }));

    };

    useEffect(() => {
        if (specificationUserId! > 0 && userRoleLogin) {
            dispatch(getUserAsync({ userSpecificationId: specificationUserId!, userRoleInput: userRoleLogin!})).unwrap().then((payload) => {
                if (payload && payload.user!.person && payload.user?.userId && payload.user.person.address) {
                    setLocalUserData({
                        "userId": payload.user.userId,
                        "person": {
                            "personId": payload.user.person.personId,
                            "firstname": payload.user.person.firstname,
                            "lastname": payload.user.person.lastname,
                            "email": payload.user.person.email,
                            "phone": payload.user.person.phone,
                            "birthday": payload.user.person.birthday,
                            "gender": payload.user.person.gender,
                            "address": {
                                "company": payload.user.person.address.company,
                                "line1": payload.user.person.address.line1,
                                "line2": payload.user.person.address.line2,
                                "zipCode": payload.user.person.address.zipCode,
                                "city": payload.user.person.address.city,
                                "country": payload.user.person.address.country,
                                "state": payload.user.person.address.state,
                            }
                        },
                        "communityName": payload.user.communityName,
                        "userRole": payload.user.userRole,
                    });
                    setLocalCountrySelected(countryCodes.find((country) => country.countryCode === person?.address?.country)?.countryName ?? 'Schweiz')
                    dispatch(getPersonPreferencesAsync(payload.user.userId));
                }
            });
        }
    }, [dispatch, person?.address?.country, specificationUserId, userRoleLogin]);

    const handleInputChange = (property: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        if (localUserData.person) {
            setLocalUserData({
                ...localUserData,
                person: { ...localUserData.person, [property]: event.target.value }
            });
        }
    };

    const handleAddressChange = (property: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        if (localUserData.person && localUserData.person.address) {
            setLocalUserData({
                ...localUserData,
                person: {
                    ...localUserData.person,
                    address: { ...localUserData.person.address, [property]: event.target.value }
                }
            });
        }
    };

    const handleBirthdayChange = (date: moment.Moment | null) => {
        const value = date ? date.format('YYYY-MM-DD') : '';
        const event = {
            target: {
                value,
                name: 'birthday',
            },
        } as React.ChangeEvent<HTMLInputElement>;
        handleInputChange('birthday')(event);
    };

    function getCountryEnumFromString(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        let inputCountryEnum = Address.country.MN;
        for (let i = 0; i < countries.length; i++) {
            if (countries[i].countryName === event.target.value) {
                inputCountryEnum = countries[i].countryCode as Address.country;
            }
        }
        return inputCountryEnum;
    }


    return (
        <Main>
            <Page>
                <Box>
                    <Typography variant="h5" gutterBottom fontWeight={700}>
                        Ändere Deine persönlichen Daten.
                    </Typography>
                    <Typography variant={'subtitle2'} color={'text.secondary'}>
                        Bitte lese unsere{' '}
                        <Link color={'primary'} href={urlPrivacyPDF} underline={'none'}>
                            Datenschutzerklährung
                        </Link>{' '}
                        um informiert zu sein, wie deine Daten von uns verwaltet werden.
                    </Typography>
                    <Box paddingY={4}>
                        <Divider />
                    </Box>
                    <FormControl fullWidth>
                <Grid container>
                <form style={{ flexGrow: '0', width: '100%' }} onSubmit={handleSubmit}>
                    <Grid item>
                        <Typography variant="h6" component="h2" gutterBottom>Allgemeine Informationen</Typography>
                    </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                        <TextField
                            label="Vorname"
                            margin="dense"
                            sx={profileStyling.textfield}
                            onChange={handleInputChange('firstname')}
                            value={localUserData.person!.firstname}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <TextField
                            label="Nachname"
                            margin="dense"
                            sx={profileStyling.textfield}
                            onChange={handleInputChange('lastname')}
                            value={localUserData.person!.lastname}
                            required
                        />
                    </Grid>
                </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={6} md={6}>
                            <TextField
                                label="Email"
                                margin="dense"
                                sx={profileStyling.textfield}
                                value={localUserData.person!.email}
                                required disabled />
                        </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <TextField
                            label="Telefon"
                            margin="dense"

                            sx={profileStyling.textfield}
                            onChange={handleInputChange('phone')}
                            value={localUserData.person!.phone}
                        />
                        </Grid>
                    </Grid>
                        <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={4}>
                            <FormControl margin="dense" fullWidth required>
                                <DatePickerVanlife
                                    handleChange={handleBirthdayChange}
                                    value={localUserData.person!.birthday ? moment(localUserData.person!.birthday) : null}
                                    label="Geburtsdatum *"
                                    name="birthday"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <FormControl margin="dense" fullWidth required>
                                <InputLabel>Geschlecht</InputLabel>
                                <Select
                                    label="Geschlecht"
                                    sx={profileStyling.textfield}
                                    onChange={(event) => {
                                        if (localUserData.person) {
                                            setLocalUserData({
                                                ...localUserData,
                                                person: {...localUserData.person, gender: event.target.value as Person.gender}
                                            });
                                        }
                                    }}
                                    value={localUserData.person!.gender}
                                >
                                    <MenuItem value={Person.gender.MALE}>Männlich</MenuItem>
                                    <MenuItem value={Person.gender.FEMALE}>Weiblich</MenuItem>
                                    <MenuItem value={Person.gender.OTHER}>Andere</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <TextField
                                    label="Community Name"
                                    helperText="Wird unter anderem bei Bewertungen angezeigt."
                                    margin="dense"
                                    sx={profileStyling.textfield}
                                    value={localUserData.communityName}
                                    onChange={(event) => { setLocalUserData({...localUserData, communityName: event.target.value}) }}
                                />
                            </Grid>
                    </Grid>
                    <Grid item>
                        <Box paddingY={4}>
                            <Divider />
                        </Box>
                        <Typography variant="h6" component="h2">Adresse</Typography>
                    </Grid>
                    <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        <TextField
                            label="Firma"
                            margin="dense"
                            sx={profileStyling.textfield}
                            onChange={handleAddressChange('company')}
                            value={localUserData.person?.address?.company}
                        />
                    </Grid>
                </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                            <TextField
                                label="Adresse Zeile 1"
                                required
                                margin="dense"
                                sx={profileStyling.textfield}
                                onChange={handleAddressChange('line1')}
                                value={localUserData.person?.address?.line1}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                            <TextField
                                label="Adresse Zeile 2"
                                margin="dense"
                                sx={profileStyling.textfield}
                                onChange={handleAddressChange('line2')}
                                value={localUserData.person?.address?.line2}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={2} sm={2} md={3}>
                            <TextField
                                label="PLZ"
                                margin="dense"
                                required
                                sx={profileStyling.textfield}
                                onChange={handleAddressChange('zipCode')}
                                value={localUserData.person?.address?.zipCode}
                            />
                        </Grid>
                        <Grid item xs={4} sm={4} md={5}>
                            <TextField
                                label="Wohnort"
                                margin="dense"
                                required
                                sx={profileStyling.textfield}
                                onChange={handleAddressChange('city')}
                                value={localUserData.person?.address?.city}
                            />
                        </Grid>
                        <Grid item xs={4} sm={4} md={4}>
                            <TextField
                                label="Kanton/Bundesland/Staat"
                                margin="dense"
                                sx={profileStyling.textfield}
                                onChange={handleAddressChange('state')}
                                value={localUserData.person?.address?.state}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item sm={12} md={12} xs={12}>
                            <TextField select id="select-country"
                                       margin="dense"
                                       sx={profileStyling.textfield}
                                       label="Land" value={localCountrySelected}
                                       onChange={(event) => {
                                             if (localUserData.person) {
                                                  setLocalCountrySelected(event.target.value);
                                                  setLocalUserData({
                                                    ...localUserData,
                                                    person: {
                                                         ...localUserData.person,
                                                         address: {
                                                              ...localUserData.person.address,
                                                              country: getCountryEnumFromString(event)
                                                         }
                                                    }
                                                  });
                                             }

                                       }} required>
                                {countries.map((country) => (
                                    <MenuItem key={country.countryCode} value={country.countryName}>
                                        {country.countryName}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                <Grid container spacing={1} mt={1}>
                    <Grid item >
                        <Button variant="contained" color="primary" type="submit" sx={{ width: '100px', float: 'left' }}>
                            Speichern
                        </Button>
                    </Grid>
                </Grid>
            </form>
                </Grid>
            </FormControl>
                </Box>
            </Page>
        </Main>
    );
}

export default PersonalDataEditor;