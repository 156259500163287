/* eslint-disable react/no-unescaped-entities */
import React, {useCallback, useEffect} from 'react';
import Box from '@mui/material/Box';
import {
    Checkbox,
    FormControlLabel,
    Grid,
    Typography
} from '@mui/material';
import {PreferencesModel} from "../../services/dataModels/PreferencesModel";
import {welcomeRegisterPage} from "../../constants/textConstant";
import {urlAGBPDF, urlPrivacyPDF} from "../../constants/constants";


interface RegisterAGBFormProps {
    disableNext: (value: boolean) => void;
    handleNext: () => void;
    localPreferences: PreferencesModel;
    setLocalPreferences: (value: PreferencesModel) => void;
}

const RegisterAGBForm: React.FC<RegisterAGBFormProps> = ({
                                                             disableNext,
                                                             handleNext,
                                                             localPreferences,
                                                             setLocalPreferences
                                                         }) => {

    const handleCheckboxFeature = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.name === "acceptedAgb") {
            setLocalPreferences({...localPreferences, acceptedAgb: !localPreferences.acceptedAgb});
        } else if (event.target.name === "acceptedSaveAndEdit") {
            setLocalPreferences({...localPreferences, acceptedSaveAndEdit: !localPreferences.acceptedSaveAndEdit});
        } else if (event.target.name === "acceptedNotifications") {
            setLocalPreferences({...localPreferences, acceptedNotifications: !localPreferences.acceptedNotifications});
        }
    }


    const validateForm = useCallback(() => {
        if (localPreferences) {
            return localPreferences.acceptedAgb &&
                localPreferences.acceptedSaveAndEdit
        }
        return false;
    }, [localPreferences]);

// Modify your useEffect hook like this
    useEffect(() => {
        disableNext(!validateForm());
    }, [disableNext, localPreferences, validateForm]);


    return (
        <Box>
            <Grid container sx={{display: 'flex', flexDirection: 'column'}}>
                <Typography variant="body2"
                            sx={{marginTop: '40px'}}>{welcomeRegisterPage.agbNewsletter}</Typography>
                <FormControlLabel sx={{marginTop: '12px'}}
                                  control={<Checkbox checked={localPreferences.acceptedNotifications}
                                                     onChange={handleCheckboxFeature}
                                                     name="acceptedNotifications" sx={{fontWeight: 'bold'}}></Checkbox>}
                                  label={<Typography variant="body2"
                                                     sx={{fontWeight: 'bold'}}>{welcomeRegisterPage.formControlNewsletter}</Typography>}/>
                <Typography variant="body2"
                            sx={{marginTop: '12px'}}>{welcomeRegisterPage.agbPrivacy}</Typography>
                <FormControlLabel sx={{marginTop: '12px'}}
                                  control={<Checkbox checked={localPreferences.acceptedSaveAndEdit}
                                                     onChange={handleCheckboxFeature}
                                                     name="acceptedSaveAndEdit" sx={{typography: 'body2'}}
                                                     required></Checkbox>}
                                  label={<a href={urlPrivacyPDF} target="_blank" rel="noreferrer"><Typography
                                      variant="body2"
                                      sx={{fontWeight: 'bold'}}>{welcomeRegisterPage.fromControlPrivacy}</Typography></a>}/>
                <Typography variant="body2" sx={{marginTop: '12px'}}>{welcomeRegisterPage.agbInfos}</Typography>
                <FormControlLabel sx={{marginTop: '12px', marginBottom: '20px'}}
                                  control={<Checkbox checked={localPreferences.acceptedAgb}
                                                     onChange={handleCheckboxFeature}
                                                     name="acceptedAgb" required></Checkbox>}
                                  label={<a href={urlAGBPDF} target="_blank" rel="noreferrer">
                                      <Typography variant="body2"
                                                  sx={{fontWeight: 'bold'}}>{welcomeRegisterPage.formControlAgb}</Typography>
                                  </a>}/>
            </Grid>
        </Box>
    );
};

export default RegisterAGBForm;