import * as React from 'react';
import Tooltip from "@mui/material/Tooltip";
import PowerIcon from "@mui/icons-material/Power";
import WaterIcon from "@mui/icons-material/WaterDrop";
import WifiIcon from "@mui/icons-material/Wifi";
import ShopIcon from "@mui/icons-material/ShoppingCart";
import SewerageIcon from "@mui/icons-material/OilBarrel";
import MountainIcon from "@mui/icons-material/Landscape";
import WaterBodyIcon from "@mui/icons-material/Water";
import CulturalOfferingsIcon from "@mui/icons-material/TheaterComedy";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import BreakfastDiningIcon from "@mui/icons-material/LocalDining";
import RoomServiceIcon from "@mui/icons-material/RoomService";
import TwentyFourHoursIcon from "@mui/icons-material/AccessTime";
import LocalParkingIcon from "@mui/icons-material/LocalParking";
import ParkingIcon from "@mui/icons-material/EmojiTransportation";
import DirectionsBusIcon from "@mui/icons-material/DirectionsTransit";
import ChildFriendlyIcon from "@mui/icons-material/ChildFriendly";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import GrillIcon from "@mui/icons-material/OutdoorGrill";
import PlayGroundIcon from "@mui/icons-material/Toys";
import PoolIcon from "@mui/icons-material/Pool";
import HotTubIcon from "@mui/icons-material/HotTub";
import MassageIcon from "@mui/icons-material/Spa";
import SaunaIcon from "@mui/icons-material/HeatPump";
import PetsIcon from "@mui/icons-material/Pets";
import SmokeFreeIcon from "@mui/icons-material/SmokeFree";
import TvIcon from "@mui/icons-material/Tv";
import KitchenIcon from "@mui/icons-material/Kitchen";
import LiquorIcon from "@mui/icons-material/Liquor";
import BathroomIcon from "@mui/icons-material/Bathroom";
import ShowerIcon from "@mui/icons-material/Shower";
import BathtubIcon from "@mui/icons-material/Bathtub";
import BalconyIcon from "@mui/icons-material/Balcony";
import ToiletIcon from "@mui/icons-material/Wc";

import {Typography} from "@mui/material";
import {Compound, Site} from "../../services/restserver-openapi";
import { StayCompound, StaySite } from '../../services/restserver-openapi';
import {EcoFriendlyIcon} from "../vanliferComponents/EcoFriendlyIcon";

interface CompoundEquipmentProps {
    compound?: StayCompound|Compound|undefined|null;
    site?: Site|StaySite|undefined;
    largeIcons?: boolean;
    showTextIfNoEquipment?: boolean;
}

export function CompoundEquipment({compound, site, largeIcons = false, showTextIfNoEquipment = false}: CompoundEquipmentProps): React.ReactElement {
    const fontSize = largeIcons ? "large" : "medium";

    interface SiteAttribute {
        attribute: boolean|undefined;
        icon: React.ReactElement;
    }

    const allAttributes: SiteAttribute[] = [
        {attribute: site?.electricity, icon: <Tooltip title="Strom" key="Elektrizität"><PowerIcon fontSize={fontSize} /></Tooltip>},
        {attribute: site?.water, icon: <Tooltip title="Wasseranschluss" key="Wasser"><WaterIcon fontSize={fontSize} /></Tooltip>},
        {attribute: site?.petsAllowed, icon: <Tooltip title="Haustiere erlaubt" key="PetsAllowed"><PetsIcon fontSize={fontSize} /></Tooltip>},
        {attribute: site?.nonSmoking, icon: <Tooltip title="Nichtraucher" key="NonSmoking"><SmokeFreeIcon fontSize={fontSize} /></Tooltip>},
        {attribute: site?.tvConnection, icon: <Tooltip title="TV/ Kabelanschluss" key="TVConnection"><TvIcon fontSize={fontSize} /></Tooltip>},
        {attribute: site?.cookingFacility, icon: <Tooltip title="Kochgelegenheit" key="CookingFacility"><KitchenIcon fontSize={fontSize} /></Tooltip>},
        {attribute: site?.minibar, icon: <Tooltip title="Minibar" key="Minibar"><LiquorIcon fontSize={fontSize} /></Tooltip>},
        {attribute: site?.sharedBathroom, icon: <Tooltip title="WC zur Mitbenutzung" key="SharedBathroom"><ToiletIcon fontSize={fontSize} /></Tooltip>},
        {attribute: site?.sharedShower, icon: <Tooltip title="Dusche zur Mitbenutzung" key="SharedShower"><BathroomIcon fontSize={fontSize} /></Tooltip>},
        {attribute: site?.privateBathroom, icon: <Tooltip title="Eignes WC" key="PrivateBathroom"><BathtubIcon fontSize={fontSize} /></Tooltip>},
        {attribute: site?.privateShower, icon: <Tooltip title="Eigene Dusche" key="PrivateShower"><ShowerIcon fontSize={fontSize} /></Tooltip>},
        {attribute: site?.refrigerator, icon: <Tooltip title="Kühlschrank" key="Refrigerator"><KitchenIcon fontSize={fontSize} /></Tooltip>},
        {attribute: site?.balconyOrTerrace, icon: <Tooltip title="Balkon/Terrasse" key="BalconyTerrace"><BalconyIcon fontSize={fontSize} /></Tooltip>},
        {attribute: compound?.wifiAvailable, icon: <Tooltip title="WiFi" key="Wifi"><WifiIcon fontSize={fontSize} /></Tooltip>},
        {attribute: compound?.sauna, icon: <Tooltip title="Sauna" key="Sauna"><SaunaIcon fontSize={fontSize} /></Tooltip>},
        {attribute: compound?.massage, icon: <Tooltip title="Massage od. Wellness" key="Massage"><MassageIcon fontSize={fontSize} /></Tooltip>},
        {attribute: compound?.hotTubOrSpa, icon: <Tooltip title="Whirlpool / SPA Pool" key="HotTubSpa"><HotTubIcon fontSize={fontSize} /></Tooltip>},
        {attribute: compound?.swimmingPool, icon: <Tooltip title="Scwhimmbad" key="SwimmingPool"><PoolIcon fontSize={fontSize} /></Tooltip>},
        {attribute: compound?.cityNearby, icon: <Tooltip title="Stadt in der Nähe" key="City"><LocationCityIcon fontSize={fontSize} /></Tooltip>},
        {attribute: compound?.mountains, icon: <Tooltip title="Berge in der Nähe" key="Mountains"><MountainIcon fontSize={fontSize} /></Tooltip>},
        {attribute: compound?.waterbody, icon: <Tooltip title="Gewässer in der Nähe" key="Waterbody"><WaterBodyIcon fontSize={fontSize} /></Tooltip>},
        {attribute: compound?.culturalSiteOrAttraction, icon: <Tooltip title="Kultur / Sehenswürdigkeit in der Nähe" key="CulturalSite"><CulturalOfferingsIcon fontSize={fontSize} /></Tooltip>},
        {attribute: compound?.cateringOrRestaurant, icon: <Tooltip title="Verpflegung / Restaurant" key="Restaurant"><RestaurantIcon fontSize={fontSize} /></Tooltip>},
        {attribute: compound?.onlyBreakfastPossible, icon: <Tooltip title="Nur Frühstück möglich" key="Breakfast"><BreakfastDiningIcon fontSize={fontSize} /></Tooltip>},
        {attribute: compound?.breadRollService, icon: <Tooltip title="Brötchenservice" key="BeadRoll"><RoomServiceIcon fontSize={fontSize} /></Tooltip>},
        {attribute: compound?.wholeDayReception, icon: <Tooltip title="24h Rezeption" key="Reception"><TwentyFourHoursIcon fontSize={fontSize} /></Tooltip>},
        {attribute: compound?.ownParkingSpace, icon: <Tooltip title="Eigener Parkplat" key="ParkingSpace"><LocalParkingIcon fontSize={fontSize} /></Tooltip>},
        {attribute: compound?.parkingNearby, icon: <Tooltip title="Parkplatz in der Nähe" key="ParkingNearby"><ParkingIcon fontSize={fontSize} /></Tooltip>},
        {attribute: compound?.publicTransportation, icon: <Tooltip title="ÖV in der Nähe" key="PublicTransport"><DirectionsBusIcon fontSize={fontSize} /></Tooltip>},
        {attribute: compound?.sustainable, icon: <Tooltip title="Nachhaltiger Betrieb" key="Sustainable"><EcoFriendlyIcon fontSize={fontSize} /></Tooltip>},
        {attribute: compound?.childFriendly, icon: <Tooltip title="Kinderfreundlich" key="ChildFriendly"><ChildFriendlyIcon fontSize={fontSize} /></Tooltip>},
        {attribute: compound?.shopOrSupermarket, icon: <Tooltip title="Shop / Supermarkt in der Nähe" key="Shop"><ShopIcon fontSize={fontSize} /></Tooltip>},
        {attribute: compound?.playGround, icon: <Tooltip title="Spielplatz" key="PlayGround"><PlayGroundIcon fontSize={fontSize} /></Tooltip>},
        {attribute: compound?.wasteWaterDisposal, icon: <Tooltip title="Abwasser/Entsorgung" key="WasteWaterDisposal"><SewerageIcon fontSize={fontSize} /></Tooltip>},
        {attribute: compound?.freshWater, icon: <Tooltip title="Frischwasser" key="FreshWater"><WaterIcon fontSize={fontSize} /></Tooltip>},
        {attribute: compound?.sportOrAnimation, icon: <Tooltip title="Sport / Animation" key="SportAnimation"><SportsSoccerIcon fontSize={fontSize} /></Tooltip>},
        {attribute: compound?.grillOrBarbecueArea, icon: <Tooltip title="Grill/ Grillstelle" key="GrillBarbecue"><GrillIcon fontSize={fontSize} /></Tooltip>},
    ];

    if (showTextIfNoEquipment) {
        if (allAttributes.every(a => !a.attribute)) {
            return <Typography>Keine Ausstattungen erfasst</Typography>;
        }
    }

    return (
        <Typography component="div" display="flex" flexWrap="wrap">
            {allAttributes.map((a) => a.attribute ? a.icon : null)}
        </Typography>
    );
}
