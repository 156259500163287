import React, {useState} from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Main from '../../layouts/Main';
import Container from '../../components/Container';
import registerImage from "../../assets/Images/LandingPageHeader.jpg"
import {Address, User} from "../../services/restserver-openapi";
import {PreferencesModel} from "../../services/dataModels/PreferencesModel";
import RegisterStepper from "../../components/registerComponents/RegisterStepper";

const RegisterVanlifer = (): JSX.Element => {
    const theme = useTheme();

    const [localUserData, setLocalUserData] = useState<User>({
        "person": {
            "firstname": "",
            "lastname": "",
            "email": "",
            "phone": "",
            "birthday": "",
            "gender": undefined,
            "address": {
                "company": "",
                "line1": "",
                "line2": "",
                "zipCode": undefined,
                "city": "",
                "state": "",
                "country": Address.country.CH,
            }
        },
        "communityName": "",
        "userRole": User.userRole.VANLIFER,
    });

    const [localPreferences, setLocalPreferences] = useState<PreferencesModel>(
        {
            "acceptedAgb": false,
            "acceptedSaveAndEdit": false,
            "acceptedNotifications": false,
        }
    )

    return (
        <Main>
            <Box
                sx={{
                    width: 1,
                    height: 1,
                    overflow: 'hidden',
                }}
            >
                <Container paddingX={0} paddingY={0} maxWidth={{ sm: 1, md: 1236 }}>
                    <Box
                        display={'flex'}
                        flexDirection={{ xs: 'column', md: 'row' }}
                        position={'relative'}
                    >
                        <Box
                            width={1}
                            order={{ xs: 2, md: 1 }}
                            display={'flex'}
                            alignItems={'center'}
                        >
                            <Container>
                                <Box marginBottom={4}>
                                    <Typography
                                        sx={{
                                            textTransform: 'uppercase',
                                            fontWeight: 'medium',
                                        }}
                                        gutterBottom
                                        color={'text.secondary'}
                                    >
                                        Registrierung
                                    </Typography>
                                    <Typography
                                        variant="h4"
                                        sx={{
                                            fontWeight: 700,
                                        }}
                                    >
                                        Registriere Dich, um Reisen zu planen
                                    </Typography>
                                    <Typography color="text.secondary">
                                        und um Teil der Open Travel Community zu werden
                                    </Typography>
                                </Box>
                                <RegisterStepper localUserData={localUserData}
                                                 setLocalUserData={setLocalUserData}
                                                 localPreferences={localPreferences} setLocalPreferences={setLocalPreferences}/>
                            </Container>
                        </Box>
                        <Box
                            sx={{
                                flex: { xs: '0 0 100%', md: '0 0 50%' },
                                position: 'relative',
                                maxWidth: { xs: '100%', md: '50%' },
                                order: { xs: 1, md: 2 },
                                minHeight: { xs: 'auto', md: 'calc(100vh - 58px)' },
                            }}
                        >
                            <Box
                                sx={{
                                    width: { xs: 1, md: '50vw' },
                                    height: '100%',
                                    position: 'relative',
                                }}
                            >
                                <Box
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        overflow: 'hidden',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            overflow: 'hidden',
                                            left: '0%',
                                            width: 1,
                                            height: 1,
                                            position: { xs: 'relative', md: 'absolute' },
                                            clipPath: {
                                                xs: 'none',
                                                md: 'polygon(10% 0%, 100% 0, 100% 100%, 0% 100%)',
                                            },
                                            shapeOutside: {
                                                xs: 'none',
                                                md: 'polygon(10% 0%, 100% 0, 100% 100%, 0% 100%)',
                                            },
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                height: { xs: 'auto', md: 1 },
                                                '& img': {
                                                    objectFit: 'cover',
                                                },
                                            }}
                                        >
                                            <Box
                                                component={'img'}
                                                loading="lazy"
                                                src={
                                                    registerImage
                                                }
                                                height={{ xs: 'auto', md: 1 }}
                                                maxHeight={{ xs: 300, md: 1 }}
                                                width={1}
                                                maxWidth={1}
                                                sx={{
                                                    filter:
                                                        theme.palette.mode === 'dark'
                                                            ? 'brightness(0.7)'
                                                            : 'none',
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Container>
            </Box>
        </Main>
    );
};

export default RegisterVanlifer;