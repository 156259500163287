import React, {useEffect, useState} from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Link,
    Typography,
    Box,
    Chip,
    TextField, Select, MenuItem, Button, InputLabel,
    FormControl, TableFooter, TablePagination
} from '@mui/material';
import {
    billingPeriodUnitGerman,
    getSubscriptionName,
    getTotalPriceWithoutCharges, isEarlyAccessSubscription, statusColor,
    statusGerman
} from "../../utils/subscriptionUtils";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {getUserOverviewAsync, setPageSize} from "../../store/Agent/userOverviewSlice";
import { User } from '../../services/restserver-openapi';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';



const TourTable = () => {
    const dispatch = useAppDispatch();
    const userOverview = useAppSelector((state) => state.userOverview.userOverview);
    const pageSize = useAppSelector((state) => state.userOverview.pageSize);
    const totalEntries = useAppSelector((state) => state.userOverview.totalEntries);
    const [filter, setFilter] = useState('');
    const [orderBy, setOrderBy] = useState('userId');
    const [direction, setDirection] = useState<'ASC' | 'DESC'>('ASC');
    const [pageNumber, setPageNumber] = useState(0);

    // useEffect(() => {
    //     dispatch(getUserOverviewAsync({ userRole, filter, pageNumber, pageSize, direction, orderBy }));
    // }, [dispatch, userRole, filter, pageNumber, pageSize, direction, orderBy]);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPageNumber(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch(setPageSize(parseInt(event.target.value, 10)));
        setPageNumber(0);
    };

    const SearchComponent = () => {
        const navigate = useNavigate();
        const [inputValue, setInputValue] = useState("");

        const handleButtonClick = () => {
            if (inputValue) {
                navigate(`/vanlifer/Tour/${inputValue}`);
            }
        };

        return (
          <div>
              <TextField
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder="Tour-ID"
              />
              <Button onClick={handleButtonClick}>Suchen</Button>
          </div>
        );
    };

    return (
        <Box>
            <SearchComponent/>
        </Box>
    );
};

export default TourTable;