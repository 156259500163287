import React, { createContext, useState, useContext, useEffect } from "react";

// Define the type of the context value
interface FilterContextType {
    compoundTypeFilter: string[];
    addCompoundTypeToFilter: (filter: string) => void;
    removeCompoundTypeToFilter: (filter: string) => void;
    clearCompoundTypeFilter: () => void;
}

// Context erstellen
const FilterContext = createContext<FilterContextType | undefined>(undefined);

// Provider für den Filter
function SearchFilterProvider({ children }: { children: React.ReactNode }) {
    const [compoundTypeFilter, setCompoundTypeFilter] = useState<string[]>([]);

    // useEffect(() => {
    //     console.log("SearchFilter hat sich geändert:", compoundTypeFilter.toString());
    // }, [compoundTypeFilter]); // Überwacht Änderungen

    const clearCompoundTypeFilter = () => {
        setCompoundTypeFilter([]); // Set the filters state to an empty array
    };

    // Funktion zum Hinzufügen eines Filters
    const addCompoundTypeToFilter = (compoundType: string) => {
        setCompoundTypeFilter((prevFilters: string[]) => {
            if (!prevFilters.includes(compoundType)) {
                return [...prevFilters, compoundType];
            }
            return prevFilters;
        });
    };

    // Funktion zum Entfernen eines Filters
    const removeCompoundTypeToFilter = (compoundType: string) => {
        setCompoundTypeFilter((prevFilters) => prevFilters.filter((f) => f !== compoundType));
    };

    return (
        <FilterContext.Provider value={{ compoundTypeFilter, addCompoundTypeToFilter, removeCompoundTypeToFilter, clearCompoundTypeFilter }}>
            {children}
        </FilterContext.Provider>
    );
}


// Hook to use the context
const useSearchFilter = (): FilterContextType => {
    const context = useContext(FilterContext);
    if (!context) {
        throw new Error("useSearchFilter must be used within a SearchFilterProvider");
    }
    return context;
};

export { SearchFilterProvider, useSearchFilter };