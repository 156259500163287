import React, {useMemo} from 'react';
import {Typography, Box, Alert, PaletteMode, useTheme, Snackbar} from '@mui/material';
import Main from "../../layouts/Main";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import {CancelablePromise, SubscriptionService, User} from "../../services/restserver-openapi";
import {chargebeePromise} from "../../utils/chargebee";
import {getUserSubscriptionsAsync} from "../../store/navBar/userSlice";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";

type Abo = {
    title: string;
    chargebeeId: string;
    price: string;
    unit: string;
    features: string[];
    isHighlighted: boolean;
};

export default function SubscribeAbo( { abos }: { abos: Abo[] })  {
    const theme = useTheme();
    const mode = theme.palette.mode;
    const dispatch = useAppDispatch();

    const isVerifiedHost = useAppSelector(state => state.user.isVerifiedHost);
    const userRole = useAppSelector(state => state.login.userRoleLogin);

    const subscriptions = useAppSelector(state => state.user.subscriptions);
    const currentAbos = useMemo(() => {
        return subscriptions.flatMap(sub =>
            sub.subscriptionItems?.map(item => item.itemPriceId ? item.itemPriceId : "") ?? []
        );
    }, [subscriptions]);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);


    const subscribeViaChargebee = async (chargebeeId: string)  => {
        const cbInstance = await chargebeePromise;

        if (!cbInstance) {
            console.error('Chargebee not loaded');
            return;
        }

        const hostedPagePromise : CancelablePromise<any> = SubscriptionService.requestSubscriptionIntent(chargebeeId, true);

        cbInstance.openCheckout({
            hostedPage: function() {
                return hostedPagePromise;
            },
            success: function() {
                dispatch(getUserSubscriptionsAsync())
                setOpenSnackbar(true);
            }, close(): void {
            }, layout: "in_app", step(currentStep: string): void {
            }
        });
    }

    if (userRole === User.userRole.HOST && !isVerifiedHost) {
        return (
            <Main>
                <Box>
                    <Alert severity="warning">
                        Verifizierung ausstehend. Die Abos können erst verwaltet werden, wenn die Verifizierung abgeschlossen ist.
                    </Alert>
                </Box>
            </Main>
        );
    }


    return (
        <Main>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                      open={openSnackbar}
                      autoHideDuration={8000}
                      onClose={() => setOpenSnackbar(false)}>
                <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{ width: '100%' }}>
                    Mitgliedschaft erfolgreich aktualisiert
                </Alert>
            </Snackbar>
            <Box paddingBottom={2}>
                { userRole === User.userRole.HOST &&
                    <Alert severity="info">
                        Nachfolgend eine Auflistung der Standardabos. Das Flex-Abo kann angepasst werden. Dazu Support kontaktieren.
                    </Alert>
                }
            </Box>
                <Grid container spacing={4}>
                    {abos.map((item, i) => (
                        <Grid item xs={12} md={12} key={i}>
                            <Box
                                component={Card}
                                height={1}
                                display={'flex'}
                                flexDirection={'column'}
                                boxShadow={item.isHighlighted ? 4 : 1}
                            >
                                <CardContent
                                    sx={{
                                        padding: 4,
                                    }}
                                >
                                    <Box
                                        display={'flex'}
                                        justifyContent={'space-between'}
                                    >
                                        <Typography variant={'h4'}>
                                            <Box component={'span'} fontWeight={600}>
                                                {item.title}
                                            </Box>
                                        </Typography>
                                    </Box>
                                    <Box marginBottom={4} display={'flex'} alignItems={'baseline'} gap={'.5rem'}>
                                        <Typography variant={'h6'} color={'primary'}>
                                            <Box component={'span'} fontWeight={600}>
                                                {item.price}
                                            </Box>
                                        </Typography>
                                        <Typography variant={'body1'} color={'primary'}>
                                            {item.unit}
                                        </Typography>
                                    </Box>

                                    <Grid container spacing={1}>
                                        {item.features.map((feature, j) => (
                                            <Grid item xs={12} key={j}>
                                                <Box
                                                    component={ListItem}
                                                    disableGutters
                                                    width={'auto'}
                                                    padding={0}
                                                >
                                                    <Box
                                                        component={ListItemAvatar}
                                                        minWidth={'auto !important'}
                                                        marginRight={2}
                                                    >
                                                        <Box
                                                            component={Avatar}
                                                            bgcolor={mode === 'light' as PaletteMode ? theme.palette.secondary.main : theme.palette.primary.main}
                                                            width={20}
                                                            height={20}
                                                        >
                                                            <svg
                                                                width={12}
                                                                height={12}
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 20 20"
                                                                fill="currentColor"
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                    clipRule="evenodd"
                                                                />
                                                            </svg>
                                                        </Box>
                                                    </Box>
                                                    <ListItemText primary={feature} />
                                                </Box>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </CardContent>
                                <CardActions sx={{ justifyContent: 'flex-end', padding: 4 }}>
                                    <Button size={'large'} variant={'contained'} disabled={currentAbos.includes(item.chargebeeId)} onClick={() => subscribeViaChargebee(item.chargebeeId)}>
                                        {currentAbos.includes(item.chargebeeId) ? "Aktuelles Abo" : "Abo abschliessen"}
                                    </Button>
                                </CardActions>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
        </Main>
    );
};