/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Coordinates } from './Coordinates';
import type { OsmInfo } from './OsmInfo';
export type PointOfInterest = {
    pointOfInterestId?: number;
    name?: string;
    coordinates?: Coordinates;
    externalLink?: string;
    addressLine1?: string;
    zipCode?: number;
    city?: string;
    country?: PointOfInterest.country;
    osmInfo?: OsmInfo;
    poiType?: PointOfInterest.poiType;
};
export namespace PointOfInterest {
    export enum country {
        AF = 'AF',
        AX = 'AX',
        AL = 'AL',
        DZ = 'DZ',
        AS = 'AS',
        AD = 'AD',
        AO = 'AO',
        AI = 'AI',
        AQ = 'AQ',
        AG = 'AG',
        AR = 'AR',
        AM = 'AM',
        AW = 'AW',
        AU = 'AU',
        AT = 'AT',
        AZ = 'AZ',
        BH = 'BH',
        BS = 'BS',
        BD = 'BD',
        BB = 'BB',
        BY = 'BY',
        BE = 'BE',
        BZ = 'BZ',
        BJ = 'BJ',
        BM = 'BM',
        BT = 'BT',
        BO = 'BO',
        BQ = 'BQ',
        BA = 'BA',
        BW = 'BW',
        BV = 'BV',
        BR = 'BR',
        IO = 'IO',
        BN = 'BN',
        BG = 'BG',
        BF = 'BF',
        BI = 'BI',
        KH = 'KH',
        CM = 'CM',
        CA = 'CA',
        CV = 'CV',
        KY = 'KY',
        CF = 'CF',
        TD = 'TD',
        CL = 'CL',
        CN = 'CN',
        CX = 'CX',
        CC = 'CC',
        CO = 'CO',
        KM = 'KM',
        CG = 'CG',
        CD = 'CD',
        CK = 'CK',
        CR = 'CR',
        CI = 'CI',
        HR = 'HR',
        CU = 'CU',
        CW = 'CW',
        CY = 'CY',
        CZ = 'CZ',
        DK = 'DK',
        DJ = 'DJ',
        DM = 'DM',
        DO = 'DO',
        EC = 'EC',
        EG = 'EG',
        SV = 'SV',
        GQ = 'GQ',
        ER = 'ER',
        EE = 'EE',
        ET = 'ET',
        FK = 'FK',
        FO = 'FO',
        FJ = 'FJ',
        FI = 'FI',
        FR = 'FR',
        GF = 'GF',
        PF = 'PF',
        TF = 'TF',
        GA = 'GA',
        GM = 'GM',
        GE = 'GE',
        DE = 'DE',
        GH = 'GH',
        GI = 'GI',
        GR = 'GR',
        GL = 'GL',
        GD = 'GD',
        GP = 'GP',
        GU = 'GU',
        GT = 'GT',
        GG = 'GG',
        GN = 'GN',
        GW = 'GW',
        GY = 'GY',
        HT = 'HT',
        HM = 'HM',
        VA = 'VA',
        HN = 'HN',
        HK = 'HK',
        HU = 'HU',
        IS = 'IS',
        IN = 'IN',
        ID = 'ID',
        IR = 'IR',
        IQ = 'IQ',
        IE = 'IE',
        IM = 'IM',
        IL = 'IL',
        IT = 'IT',
        JM = 'JM',
        JP = 'JP',
        JE = 'JE',
        JO = 'JO',
        KZ = 'KZ',
        KE = 'KE',
        KI = 'KI',
        KP = 'KP',
        KR = 'KR',
        KW = 'KW',
        KG = 'KG',
        LA = 'LA',
        LV = 'LV',
        LB = 'LB',
        LS = 'LS',
        LR = 'LR',
        LY = 'LY',
        LI = 'LI',
        LT = 'LT',
        LU = 'LU',
        MO = 'MO',
        MK = 'MK',
        MG = 'MG',
        MW = 'MW',
        MY = 'MY',
        MV = 'MV',
        ML = 'ML',
        MT = 'MT',
        MH = 'MH',
        MQ = 'MQ',
        MR = 'MR',
        MU = 'MU',
        YT = 'YT',
        MX = 'MX',
        FM = 'FM',
        MD = 'MD',
        MC = 'MC',
        MN = 'MN',
        ME = 'ME',
        MS = 'MS',
        MA = 'MA',
        MZ = 'MZ',
        MM = 'MM',
        NA = 'NA',
        NR = 'NR',
        NP = 'NP',
        NL = 'NL',
        NC = 'NC',
        NZ = 'NZ',
        NI = 'NI',
        NE = 'NE',
        NG = 'NG',
        NU = 'NU',
        NF = 'NF',
        MP = 'MP',
        OM = 'OM',
        PK = 'PK',
        PW = 'PW',
        PS = 'PS',
        PA = 'PA',
        PG = 'PG',
        PY = 'PY',
        PE = 'PE',
        PH = 'PH',
        PN = 'PN',
        PL = 'PL',
        PT = 'PT',
        PR = 'PR',
        QA = 'QA',
        RE = 'RE',
        RO = 'RO',
        RU = 'RU',
        RW = 'RW',
        BL = 'BL',
        SH = 'SH',
        KN = 'KN',
        LC = 'LC',
        MF = 'MF',
        PM = 'PM',
        VC = 'VC',
        WS = 'WS',
        SM = 'SM',
        ST = 'ST',
        SA = 'SA',
        SN = 'SN',
        RS = 'RS',
        SC = 'SC',
        SL = 'SL',
        SG = 'SG',
        SX = 'SX',
        SK = 'SK',
        SI = 'SI',
        SB = 'SB',
        SO = 'SO',
        ZA = 'ZA',
        GS = 'GS',
        SS = 'SS',
        ES = 'ES',
        LK = 'LK',
        SD = 'SD',
        SR = 'SR',
        SJ = 'SJ',
        SZ = 'SZ',
        SE = 'SE',
        CH = 'CH',
        SY = 'SY',
        TW = 'TW',
        TJ = 'TJ',
        TZ = 'TZ',
        TH = 'TH',
        TL = 'TL',
        TG = 'TG',
        TK = 'TK',
        TO = 'TO',
        TT = 'TT',
        TN = 'TN',
        TR = 'TR',
        TM = 'TM',
        TC = 'TC',
        TV = 'TV',
        UG = 'UG',
        UA = 'UA',
        AE = 'AE',
        GB = 'GB',
        US = 'US',
        UM = 'UM',
        UY = 'UY',
        UZ = 'UZ',
        VU = 'VU',
        VE = 'VE',
        VN = 'VN',
        VG = 'VG',
        VI = 'VI',
        WF = 'WF',
        EH = 'EH',
        YE = 'YE',
        ZM = 'ZM',
        ZW = 'ZW',
    }
    export enum poiType {
        ZOO = 'ZOO',
        MUSEUM = 'MUSEUM',
        VIEWPOINT = 'VIEWPOINT',
        THEME_PARK = 'THEME_PARK',
        OTHER_POI = 'OTHER_POI',
        ATTRACTION = 'ATTRACTION',
        AMUSEMENT_ARCADE = 'AMUSEMENT_ARCADE',
        AXE_THROWING = 'AXE_THROWING',
        BATHING_PLACE = 'BATHING_PLACE',
        BATHING_PLATFORM = 'BATHING_PLATFORM',
        BEACH_RESORT = 'BEACH_RESORT',
        BIKE_PARK = 'BIKE_PARK',
        BIRD_HIDE = 'BIRD_HIDE',
        BOULES = 'BOULES',
        BOWLING = 'BOWLING',
        BOWLING_ALLEY = 'BOWLING_ALLEY',
        CLUB = 'CLUB',
        DANCE = 'DANCE',
        DISC_GOLF_COURSE = 'DISC_GOLF_COURSE',
        DOG_PARK = 'DOG_PARK',
        ESCAPE_GAME = 'ESCAPE_GAME',
        FILM_FESTIVAL = 'FILM_FESTIVAL',
        FIREPIT = 'FIREPIT',
        FISHING = 'FISHING',
        FITNESS_CENTRE = 'FITNESS_CENTRE',
        FITNESS_STATION = 'FITNESS_STATION',
        GARDEN = 'GARDEN',
        GOLF_COURSE = 'GOLF_COURSE',
        HACKERSPACE = 'HACKERSPACE',
        GREENERY = 'GREENERY',
        HIGH_ROPES_COURSE = 'HIGH_ROPES_COURSE',
        HORSE_RIDING = 'HORSE_RIDING',
        HOT_SPRING = 'HOT_SPRING',
        HOT_TUB = 'HOT_TUB',
        ICE_RINK = 'ICE_RINK',
        INDOOR_PLAY = 'INDOOR_PLAY',
        MINIATURE_GOLF = 'MINIATURE_GOLF',
        NATURE_RESERVE = 'NATURE_RESERVE',
        PADDLING_POOL = 'PADDLING_POOL',
        PICNIC_TABLE = 'PICNIC_TABLE',
        PIER = 'PIER',
        PISTE = 'PISTE',
        PLAYGROUND = 'PLAYGROUND',
        SAUNA = 'SAUNA',
        SMALL_ANIMAL_PARK = 'SMALL_ANIMAL_PARK',
        SPA = 'SPA',
        SPORT = 'SPORT',
        SPORT_HALL = 'SPORT_HALL',
        SPORTS = 'SPORTS',
        SPORTS_CENTRE = 'SPORTS_CENTRE',
        SPORTS_HALL = 'SPORTS_HALL',
        STADIUM = 'STADIUM',
        SWIMMING_AREA = 'SWIMMING_AREA',
        SWIMMING = 'SWIMMING',
        SWIMMING_POOL = 'SWIMMING_POOL',
        TRACK = 'TRACK',
        TRAMPOLINE_PARK = 'TRAMPOLINE_PARK',
        TRAMPOLIN = 'TRAMPOLIN',
        TURKISH_BATH = 'TURKISH_BATH',
        WATER_SLIDE = 'WATER_SLIDE',
        WATER_PARK = 'WATER_PARK',
        WILDLIFE_HIDE = 'WILDLIFE_HIDE',
    }
}

