import React, {useState} from 'react';
import {Button, Checkbox, Typography} from '@mui/material';
import {useSearchFilter} from "./SearchFilterProvider";
import BetaBadge from "../../presentationalComponents/BetaBadge";


const MoreOptionsComponent = () => {

    const { addCompoundTypeToFilter, removeCompoundTypeToFilter } = useSearchFilter();

    const [selectedOptions, setSelectedOptions] = useState({
        Hotels: false,
        GasthausPension: false,
        Appartement: false,
        Campingplatz: false,
        WomoStellplatz: false,
        Farm: false,
        Parkplatz: false,
        Andere: false
    });

    const handleSearchOptionsChange = (event: { target: { name: any; checked: any; }; }, compoundType: string) => {
        const {name, checked} = event.target;
        setSelectedOptions({
            ...selectedOptions,
            [name]: checked
        });
        if (checked) {
            addCompoundTypeToFilter(compoundType);
        } else {
            removeCompoundTypeToFilter(compoundType);
        }
    };

    return (
        <div style={{padding: '20px', backgroundColor: 'primary.light'}}>
            <label>
                <Checkbox
                    name="Hotels"
                    checked={selectedOptions.Hotels}
                    onChange={(e) => handleSearchOptionsChange(e, "HOTEL")}
                />
                Hotels
            </label>
            <br/>
            <label>
                <Checkbox
                    name="GasthausPension"
                    checked={selectedOptions.GasthausPension}
                    onChange={(e) => handleSearchOptionsChange(e, "GUESTHOUSE")}
                />
                Gasthaus/Pension
            </label>
            <br/>
            <label>
                <Checkbox
                    name="Appartement"
                    checked={selectedOptions.Appartement}
                    onChange={(e) => handleSearchOptionsChange(e, "PRIVATE_ACCOMODATION")}
                />
                Appartement
            </label>
            <br/>
            <label>
                <Checkbox
                    name="Campingplatz"
                    checked={selectedOptions.Campingplatz}
                    onChange={(e) => handleSearchOptionsChange(e, "CAMPSITE")}
                />
                Campingplatz
            </label>
            <br/>
            <label>
                <Checkbox
                    name="WomoStellplatz"
                    checked={selectedOptions.WomoStellplatz}
                    onChange={(e) => handleSearchOptionsChange(e, "RV_PITCH")}
                />
                Womo-Stellplatz
            </label>
            {/*<br/>*/}
            {/*<label>*/}
            {/*    <Checkbox*/}
            {/*        name="Bauernhof"*/}
            {/*        checked={selectedOptions.Farm}*/}
            {/*        onChange={(e) => handleSearchOptionsChange(e, "FARM")}*/}
            {/*    />*/}
            {/*    Bauernhof*/}
            {/*</label>*/}
            <br/>
            <label>
                <Checkbox
                    name="Parkplatz"
                    checked={selectedOptions.Parkplatz}
                    onChange={(e) => handleSearchOptionsChange(e, "PARKING_LOT")}
                />
                Parkplatz
            </label>
            <br/>
            <label>
                <Checkbox
                    name="Andere"
                    checked={selectedOptions.Andere}
                    onChange={(e) => handleSearchOptionsChange(e, "OTHER")}
                />
                Andere
            </label>
        </div>
    );
};

// Weitere Optionen Komponente mit aufklappbarem Bereich
function SearchOptions() {

    const { clearCompoundTypeFilter } = useSearchFilter();

    const [isOpen, setIsOpen] = useState(false);
    const toggleOptions = () => {
        clearCompoundTypeFilter();
        setIsOpen(!isOpen);
    };

    return (
        <div>
            <Button fullWidth variant="text" color={'primary'} onClick={toggleOptions}>
                {isOpen ? 'Weniger Optionen' : 'Weitere Optionen'} <BetaBadge/>
            </Button>
            {isOpen && (
                <div style={{marginTop: '10px'}}>
                    <Typography variant={'subtitle2'} color={'text.secondary'}>
                        Bitte wähle die Arten von Unterkünften aus, die für Deine Reise in Frage kommen:
                    </Typography>
                    <MoreOptionsComponent/>
                </div>
            )}
        </div>
    );
};

export default SearchOptions;