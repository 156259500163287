import React, {createContext, useContext, useState} from 'react';
import { Button, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import DatePickerVanlife from '../../presentationalComponents/DatePickerVanlife';
import moment, { Moment } from 'moment/moment';
import FormControl from '@mui/material/FormControl';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { Region } from '../../../services/restserver-openapi';
import { fetchAllGeneratedTours, resetTourDistances } from '../../../store/Vanlifer/tourSlice';
import {setSearchFormData} from "../../../store/Vanlifer/vanliferComponentsSlice";
import SearchOptions from "./SearchOptions";
import {useSearchFilter} from "./SearchFilterProvider";

function SearchTours() {

  const currentDate = moment();
  const dispatch = useAppDispatch();

  const {compoundTypeFilter} = useSearchFilter();

  const startDateFromState = useAppSelector(state => state.vanliferComponents.searchFormData?.startDate);
  const endDateFromState = useAppSelector(state => state.vanliferComponents.searchFormData?.endDate);
  const regionIdFromState = useAppSelector(state => state.vanliferComponents.searchFormData?.regionId);
  const [startDate, setStartDate] = React.useState<Moment | null>(startDateFromState ? startDateFromState : null); //moment('2022-06-01', 'YYYY-MM-DD')
  const [endDate, setEndDate] = React.useState<Moment | null>(endDateFromState ? endDateFromState : null);
  const [selectedRegionId, setSelectedRegionId] = useState<number | undefined>(regionIdFromState ? regionIdFromState : undefined);
  const regions: Region[] = useAppSelector(state => state.region.regions);

  const handleStartDateChange = (newValue: Moment | null) => {
    if (endDate && newValue && newValue.isAfter(endDate)) {
      setEndDate(null);
      dispatch(setSearchFormData({ startDate: newValue, endDate: null, regionId: selectedRegionId }));
    } else {
      dispatch(setSearchFormData({ startDate: newValue, endDate: endDate, regionId: selectedRegionId }));
      setStartDate(newValue);
    }  };
  const handleEndDateChange = (newValue: Moment | null) => {
    if (startDate && newValue && newValue.isBefore(startDate)) {
      // prevent end date from being before start date
      return;
    } else {
      dispatch(setSearchFormData({ startDate: startDate, endDate: newValue, regionId: selectedRegionId }));
      setEndDate(newValue);
    }
  };

  const handleRegionSelection = (event: SelectChangeEvent<number | undefined>) => {
    if (event.target.value) {
      dispatch(setSearchFormData({ startDate: startDate, endDate: endDate, regionId: +event.target.value }));
      setSelectedRegionId(+event.target.value);
    }
  };
  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    dispatch(resetTourDistances());
    console.log("Start Search with Filter: %s", compoundTypeFilter.toString());
    dispatch(fetchAllGeneratedTours({
      fromDate: Number(startDate?.add(12, 'hours').utc().startOf('day').valueOf()),
      toDate: Number(endDate?.add(12, 'hours').utc().startOf('day').valueOf()),
      regionId: Number(selectedRegionId),
      compoundTypes: compoundTypeFilter,
    }));
  };

  return (
    <form data-testid="availability-form" onSubmit={onSubmit}>
      <Grid container direction="row" columnSpacing={{ xs: 1, sm: 2, md: 3 }} rowSpacing={1} justifyContent="left"
            alignContent="center" padding={0}>
        <Grid item xs={12} sm={10} md={8}>
          <DatePickerVanlife handleChange={handleStartDateChange} value={startDate} label={'Startdatum'}
                             name={'Start Datum'} minDate={currentDate} />
        </Grid>
        <Grid item xs={12} sm={10} md={8}>
          <DatePickerVanlife handleChange={handleEndDateChange} value={endDate} label={'Enddatum'} name={'End Datum'}
                             minDate={startDate === null ? currentDate : startDate} />
        </Grid>
      </Grid>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }} justifyContent="left" alignContent="center"
            my={0} padding={0}>
        <Grid item xs={12} sm={10} md={8}>
          <FormControl fullWidth required>
            <InputLabel id="select-region">Wunschregion</InputLabel>
            <Select fullWidth labelId="select-region" id="select-region" label="Wunschregion" required
                    value={selectedRegionId ? selectedRegionId : ''}
                    onChange={handleRegionSelection}>
              {
                regions.map((item) => (
                  <MenuItem sx={{
                    '&:hover': {
                      backgroundColor: 'secondary.main',
                    },
                    '&.Mui-selected': {
                      backgroundColor: 'primary.main.light',
                    },
                  }} key={item.regionId} value={item.regionId}> {item.name} </MenuItem>))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={10} md={8}>
          <Button fullWidth variant="contained" type="submit" color={'primary'}>Reisevorschläge anzeigen</Button>
        </Grid>
        <Grid item xs={12} sm={10} md={8}>
          <SearchOptions/>
        </Grid>
      </Grid>
    </form>
  );
}

export default SearchTours;
