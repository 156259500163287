import React, {useEffect, useState} from 'react';
import Main from '../../layouts/Main';
import {useNavigate, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {fetchAllRegions, fetchCompoundsOfRegion, setRegionById} from "../../store/Vanlifer/regionSlice";
import Container from "../../components/Container";
import OfferImages from "../../components/presentationalComponents/OfferImages";
import {Box, Button, Grid, Typography} from '@mui/material';
import {Compound} from "../../services/restserver-openapi";
import {unwrapResult} from "@reduxjs/toolkit";
import {setSearchFormData} from "../../store/Vanlifer/vanliferComponentsSlice";
import CompoundsListing from "../../components/presentationalComponents/CompoundsListing";

function RegionSinglePage() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { regionId } = useParams();
    const region = useAppSelector(state => state.region.region)
    const [sortedCompounds, setSortedCompounds] = useState<Compound[]>([])

    useEffect(() => {
        dispatch(fetchAllRegions()).then(() => {
            dispatch(setRegionById(Number(regionId)));
            setSortedCompounds([]);
            dispatch(fetchCompoundsOfRegion(Number(regionId))).then(unwrapResult).then(fetchedCompounds => {
                const compoundsVisible = fetchedCompounds.filter(compound => compound.visibleCompound);
                const compoundsSorted = [...compoundsVisible].sort((a, b) => {
                    // If a has images and b doesn't, a should come first
                    if (a.imageIds && a.imageIds.length > 0 && (!b.imageIds || b.imageIds.length === 0)) {
                        return -1;
                    }
                    // If b has images and a doesn't, b should come first
                    if (b.imageIds && b.imageIds.length > 0 && (!a.imageIds || a.imageIds.length === 0)) {
                        return 1;
                    }
                    // If both a and b have images, or both don't have images, sort them randomly
                    return 0.5 - Math.random();
                });
                setSortedCompounds(compoundsSorted);
            });
        });
    }, [dispatch, regionId]);

    function generateTourWithRegion() {
        dispatch(setSearchFormData({startDate: null, endDate: null, regionId: Number(regionId)}));
        navigate('/vanlifer/TravelPlanningOverview');
    }

    return (
        <Main>
            <Container>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={7}>
                        <Box display="flex" flexDirection="column" height="100%">
                            <Box>
                                <Typography variant="h3" component="h1">{region?.name}</Typography>
                                <Typography variant="body1">
                                    {region?.description}
                                </Typography>
                            </Box>
                            <Box mt="auto" mb="auto">
                                <Button variant="contained" color="primary" onClick={generateTourWithRegion}>Tour für Region {region.name} generieren</Button>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <OfferImages imageIds={region?.imageIds || []} altText={`Bild von der Region ${region?.name}`}
                                     showLargeImage={true}/>
                    </Grid>
                </Grid>
                <CompoundsListing compounds={sortedCompounds} title={`Campingplätze in der Region ${region?.name}`} />
            </Container>
        </Main>
    );
}

export default RegionSinglePage