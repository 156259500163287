import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { alpha, useTheme } from '@mui/material/styles';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Container from '../Container';
import { PaletteMode } from '@mui/material';
import { useAppSelector } from '../../hooks/hooks';
import { User } from '../../services/restserver-openapi';


const ProfileHeader = (): JSX.Element => {
  const theme = useTheme();
  const person = useAppSelector((state) => state.user.user.person);
  const compoundId = useAppSelector(state => state.compounds.selectedCompound?.compoundId);
  const mode = theme.palette.mode;
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const navigate = useNavigate();
  const userRoleLogin = useAppSelector((state) => state.login.userRoleLogin);
  if (userRoleLogin === undefined) {
    navigate('/profile/personal');
  }

  const HeaderContent = () => (
    <Box data-aos={isMd ? 'fade-right' : 'fade-up'}>
      <Typography
        sx={{
          textTransform: 'uppercase',
          fontWeight: 'medium',
        }}
        gutterBottom
        color={'text.secondary'}
      >
        Einstellungen
      </Typography>
      <Box marginBottom={2}>
        <Typography
          variant="h2"
          color="text.primary"
          sx={{
            fontWeight: 700,
          }}
        >
          Verwalte{' '}
          <Typography
            color={'text.primary'}
            component={'span'}
            variant={'inherit'}
            sx={{
              background: `linear-gradient(180deg, transparent 82%, ${alpha(
                mode === 'light' as PaletteMode ? theme.palette.secondary.main : theme.palette.primary.main,
                0.3,
              )} 0%)`,
            }}
          >
            Dein Profil
          </Typography>
        </Typography>
      </Box>
      <Box marginBottom={3}>
        {userRoleLogin === User.userRole.HOST &&
          <Typography variant="h6" component="p" color="text.secondary">
            Es freut uns sehr, Dich als Gastgeber bei Vanlife Travel begrüßen zu dürfen. Wir möchten Dir die Verwaltung
            Deiner Unterkünfte vereinfachen und Dir helfen, das Beste aus Deiner Gastgeberrolle herauszuholen. Solltest
            Du Fragen haben oder Unterstützung benötigen, steht unser Support Team jederzeit gerne zur Verfügung. Wir
            wünschen Dir viel Erfolg und freuen uns auf eine erfolgreiche Zusammenarbeit!
          </Typography>
        }
        {userRoleLogin === User.userRole.VANLIFER &&
          <Typography variant="h6" component="p" color="text.secondary">
            Schön dass Du Teil der Open Travel Community bist. Wir möchten Dir die Verwaltung Deines Profils
            so einfach wie möglich machen. In diesem Bereich kannst Du Deine Profileinstellungen verwalten. Bitte
            achte darauf, dass Du korrekte Daten angibst, denn sonst können wir die Gastgeber nicht informieren, wer zu
            Ihnen kommt und Deine Reise kann nicht so stattfinden, wie Du es Dir gewünscht hast. Solltest Du Fragen oder
            Unterstützung benötigen steht dir unser Support Team gerne zur Verfügung. Wir wünschen Dir viel Spass.
          </Typography>
        }
      </Box>
    </Box>
  );


  return (
    <Container paddingX={0} paddingY={0} maxWidth={{ sm: 1, md: 1236 }}>
      <Box
        display={'flex'}
        flexDirection={{ xs: 'column', md: 'row' }}
        position={'relative'}
      >
        <Box
          width={1}
          order={{ xs: 2, md: 1 }}
          display={'flex'}
          alignItems={'center'}
        >
          <Container>
            <HeaderContent />
          </Container>
        </Box>
      </Box>
    </Container>
  );
};

export default ProfileHeader;
