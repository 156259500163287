import React, { useMemo, useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  Stack,
  Table,
  TableCell,
  TableHead,
  TableRow, TableBody, Snackbar, Alert, Link,
} from '@mui/material';
import Main from '../../layouts/Main';
import Container from '../../components/Container';
import { useAppDispatch } from '../../hooks/hooks';
import {
  CancelablePromise,
  Subscription,
  SubscriptionEntitlement,
  SubscriptionService,
  User,
} from '../../services/restserver-openapi';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import { chargebeePromise } from '../../utils/chargebee';
import { getUserSubscriptionsAsync } from '../../store/navBar/userSlice';
import {
  billingPeriodUnitGerman, convertPrice, formatChargebeeDate,
  getSubscriptionName,
  getTotalPriceWithoutCharges,
  isEarlyAccessSubscription, isHostSubscription, statusGerman,
} from '../../utils/subscriptionUtils';
import SelfServicePortal from './SelfServicePortal';
import { urlPrivacyPDF } from '../../constants/constants';
import Divider from '@mui/material/Divider';
import Page from '../Page';

interface AboOverviewProps {
  subscriptions: Subscription[];
  userRole: User.userRole;
  totalOwnedSites?: number;
  maxAllowedSitesWithoutAddon?: string;
  userEntitlements: SubscriptionEntitlement[];
  editable: boolean;
}

export default function AboOverview(props: AboOverviewProps) {
  const dispatch = useAppDispatch();
  const { subscriptions, userRole, totalOwnedSites, maxAllowedSitesWithoutAddon, userEntitlements, editable } = props;

  const numberOfAllowedSites = useMemo(() => {
    return userEntitlements.find(entitlement => entitlement.featureId === 'hostownednumberofsites')?.value || '10';
  }, [userEntitlements]);

  const isHost = useMemo(() => userRole === User.userRole.HOST, [userRole]);
  const siteIsUnlimited = useMemo(() => numberOfAllowedSites === 'Unlimited', [numberOfAllowedSites]);
  const siteIsOverLimit = useMemo(() => totalOwnedSites ? totalOwnedSites > parseInt(numberOfAllowedSites) : false, [totalOwnedSites, numberOfAllowedSites]);
  const siteIsNearLimit = useMemo(() => totalOwnedSites ? totalOwnedSites >= parseInt(numberOfAllowedSites) - 2 : false, [totalOwnedSites, numberOfAllowedSites]);
  const isOverLimitWithoutAddon = useMemo(() => {
    if (maxAllowedSitesWithoutAddon === 'Unlimited') {
      return false;
    } else {
      if (totalOwnedSites && maxAllowedSitesWithoutAddon) {
        return totalOwnedSites > parseInt(maxAllowedSitesWithoutAddon);
      }
      return true;
    }
  }, [totalOwnedSites, maxAllowedSitesWithoutAddon]);


  const [openSnackbar, setOpenSnackbar] = useState(false);


  async function updateHostAddon(addOrRemove: boolean) { // addOrRemove: true = add, false= remove
    if (!isHost) {
      return;
    }
    const cbInstance = await chargebeePromise;

    if (!cbInstance) {
      console.error('Chargebee not loaded');
      return;
    }

    const hostedPagePromise: CancelablePromise<any> = SubscriptionService.requestSubscriptionIntent('HostAdditionalSitesAddon-CHF-Monthly', addOrRemove);

    cbInstance.openCheckout({
      hostedPage: function() {
        return hostedPagePromise;
      },
      success: function() {
        dispatch(getUserSubscriptionsAsync());
        setOpenSnackbar(true);
      }, close(): void {
      }, layout: 'in_app', step(currentStep: string): void {
      },
    });
  }


  return (
    <Main><Page>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={openSnackbar}
                autoHideDuration={8000}
                onClose={() => setOpenSnackbar(false)}>
        <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{ width: '100%' }}>
          Abo erfolgreich aktualisiert
        </Alert>
      </Snackbar>
      <Box>
        {subscriptions.map((subscription, index) => (
          <><Container>
            <Typography variant="h4" gutterBottom fontWeight={700}>
              Deine Mitgliedschaft in der Übersicht
            </Typography>
            <Typography variant={'subtitle2'} color={'text.secondary'}>
              Hier findest Du alle Informationen zu Deiner Mitgliedschaft. Nutze das Self-Service Portal um Deine Mitgliedschaft anzupassen, Rechnungen einzusehen oder Deine Zahlungsdaten zu ändern.
            </Typography>
            <Box marginTop={'1rem'} marginBottom={'1rem'}>
              <Divider />
            </Box>
            <Grid container>
              <Grid item xs={12} sm={4}>
                <Typography component="p" variant="body1" style={{ fontWeight: 'bold' }}>Art/Typ:</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Stack direction="row"
                       justifyContent="space-between"
                       alignItems="center"
                       spacing={12}>
                  <Typography component={'p'} variant="body1">{getSubscriptionName(subscription)}</Typography>
                  {subscription.hasScheduledChanges &&
                    <Chip label="Änderung" color="warning" />}
                </Stack>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography component="p" variant="body1" style={{ fontWeight: 'bold' }}>Preis:</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Typography component={'p'}
                            variant="body1">{getTotalPriceWithoutCharges(subscription)} {!isEarlyAccessSubscription(subscription) ? ' / ' + billingPeriodUnitGerman(subscription.billingPeriodUnit) : ''}</Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="body1" fontWeight="bold">
                  Status:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Typography variant="body1">
                  {statusGerman(subscription.status)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="body1" fontWeight="bold">
                  Startdatum:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Typography variant="body1">
                  {formatChargebeeDate(subscription.startedAt!)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="body1" fontWeight="bold">
                  Letzte Änderung:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Typography variant="body1">
                  {formatChargebeeDate(subscription.updatedAt!)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="body1" fontWeight="bold">
                  Nächste Zahlung:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Typography variant="body1">
                  {formatChargebeeDate(subscription.nextBillingAt!)}
                </Typography>
              </Grid>

              {/*<Grid item xs={12}>*/}
              {/*  <Typography variant="body1">*/}
              {/*    {subscription.addons?.map(addon => addon.id).join(', ')}*/}
              {/*  </Typography>*/}
              {/*</Grid>*/}
              <Typography id="modal-description" sx={{ whiteSpace: 'pre-line' }}><br /></Typography>
              <Grid item xs={12} sm={4}>
                <Typography variant="body1" fontWeight="bold">
                  Laufende Services:
                </Typography>
              </Grid>
              <Typography id="modal-description" sx={{ whiteSpace: 'pre-line' }}><br /></Typography>
              <Grid item xs={12} sm={12}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell><Typography
                        color={'text.secondary'}
                        variant={'caption'}
                        fontWeight={700}
                        sx={{ textTransform: 'uppercase' }}
                      >Service Name / ID</Typography></TableCell>
                      <TableCell><Typography
                        color={'text.secondary'}
                        variant={'caption'}
                        fontWeight={700}
                        sx={{ textTransform: 'uppercase' }}
                      >Preis {!isEarlyAccessSubscription(subscription) ? ' pro ' + billingPeriodUnitGerman(subscription.billingPeriodUnit) : ''}</Typography></TableCell>
                      <TableCell><Typography
                        color={'text.secondary'}
                        variant={'caption'}
                        fontWeight={700}
                        sx={{ textTransform: 'uppercase' }}
                      >Servicetyp</Typography></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {subscription.subscriptionItems?.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {item.itemPriceId}
                        </TableCell>
                        <TableCell>
                          {convertPrice(item.amount!, subscription.currencyCode!)} {item.itemType === 'CHARGE' ? ' (Wurde einmalig berrechnet)' : ''}
                        </TableCell>
                        <TableCell>
                          {item.itemType}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
              <Grid item xs={12} sm={12} marginTop={'1rem'} marginBottom={'1rem'}>
                <SelfServicePortal subscriptions={subscriptions} />
              </Grid>


              {isHostSubscription(subscription) && isHost && (
                <>
                   <Grid container alignItems="center" spacing={2}>
                    <Grid item>
                      <Typography variant="body1"
                                  color={siteIsOverLimit ? 'error' : siteIsNearLimit ? 'warning' : 'inherit'}>
                        <b>Anzahl Unterkünfte:</b> {totalOwnedSites}/{siteIsUnlimited ? '∞' : numberOfAllowedSites}
                      </Typography>
                    </Grid>
                    <Grid item>
                      {editable && (
                        <>

                          {subscription.subscriptionItems?.find(item => item.itemPriceId === 'HostAdditionalSitesAddon-CHF-Monthly') ? (
                            <>
                              <Typography variant="subtitle1">
                                Wenn du das AddOn nicht mehr benötigt kannst du es
                                kündigen. Die Kündigung giltet per Sofort.
                                Der Betrag wird anteilig zurückerstattet.
                              </Typography>
                              <Tooltip
                                title={isOverLimitWithoutAddon ? `Entferne alle Stellplätze über dem Limit von ${maxAllowedSitesWithoutAddon} um AddOn abbestellen` : ''}>
                                                                        <span>
                                                                            <Button variant="contained"
                                                                                    disabled={isOverLimitWithoutAddon}
                                                                                    onClick={() => updateHostAddon(false)}>
                                                                                AddOn kündigen
                                                                            </Button>
                                                                        </span>
                              </Tooltip>

                            </>
                          ) : (
                            <>
                              <Typography variant="subtitle1">
                                Kaufe für unbegrenzte Stellplätze das Addon für 10
                                CHF im Monat.
                                Solltest du das AddOn mitten in der Aboperiode
                                kaufen, wird der Betrag anteilig berechnet.
                              </Typography>
                              <Button variant="contained" color="primary"
                                      onClick={() => updateHostAddon(true)}>
                                AddOn abonnieren
                              </Button>
                            </>

                          )}
                        </>
                      )}
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Container>
          </>
        ))}
      </Box>
    </Page>
    </Main>
  );
};