/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { StayOverview } from './StayOverview';
export type TourOverview = {
    tourId?: number;
    vanliferId?: number;
    name?: string;
    price?: number;
    rating?: number;
    status?: TourOverview.status;
    tourclass?: TourOverview.tourclass;
    statusDate?: number;
    serviceFee?: number;
    stays?: Array<StayOverview>;
    tourCompleted?: boolean;
};
export namespace TourOverview {
    export enum status {
        GENERATED = 'GENERATED',
        TAGGED = 'TAGGED',
        RESERVED = 'RESERVED',
        BOOKED = 'BOOKED',
        CANCELED = 'CANCELED',
    }
    export enum tourclass {
        CLASS0 = 'Class0',
        CLASS1 = 'Class1',
        CLASS2 = 'Class2',
    }
}

