import React, {useEffect} from 'react';
import {Grid, Typography, useTheme} from "@mui/material";
import SingleSiteProposalCard from "./SingleSiteProposalCard";
import SVGComponent from "../presentationalComponents/SVGComponent";
import {useAppSelector} from "../../hooks/hooks";
import {StayDetail} from "../../services/restserver-openapi";
import {Timeline, TimelineConnector, TimelineContent, TimelineItem, TimelineSeparator} from "@mui/lab";

type TourSiteProposalProp = {
    disableEditing: boolean;
}

function TourSiteProposal(props: TourSiteProposalProp) {
    const theme = useTheme();

    const siteListFromChoosedTour = useAppSelector(state => state.tour.choosedTourDetail);
    const choosedTourDetail = useAppSelector(state => state.tour.choosedTourDetail);
    const detailTourDistancesList = useAppSelector(state => state.tour.detailedTourDistances);

    useEffect(() => {
        console.log(siteListFromChoosedTour);
    }, [choosedTourDetail])

    return (
        <div>
            <Timeline sx={{display: 'flex', justifyContent: 'center'}}>
                <Grid display="flex" justifyContent="center" alignItems="center" style={{
                    border: '1px solid',
                    borderColor: `${theme.palette.primary.main}`,
                    padding: '10px',
                    borderRadius: '7px'
                }}>
                    <Typography variant="h5">Start</Typography>
                </Grid>
                <TimelineItem sx={{display: 'flex', justifyContent: 'center'}}>
                    <TimelineSeparator>
                        <TimelineConnector/>
                        <Typography color={"primary"}>
                            <SVGComponent iconValue="camper"/>
                        </Typography>
                        <TimelineConnector/>
                    </TimelineSeparator>
                    <TimelineContent/>
                </TimelineItem>
                <Grid>
                    {
                        [...siteListFromChoosedTour.stays!]
                            .sort((a, b) => new Date(a.fromDate!).getTime() - new Date(b.fromDate!).getTime())
                            .map((stay: StayDetail, index: number) =>
                                <SingleSiteProposalCard
                                    key={stay.stayId}
                                    stay={stay}
                                    tourDistance={detailTourDistancesList![index]}
                                    disableEditing={props.disableEditing}/>
                            )
                    }
                </Grid>
                <Grid display="flex" justifyContent="center" alignItems="center"
                      style={{
                          border: '1px solid',
                          borderColor: `${theme.palette.primary.main}`,
                          padding: '10px',
                          borderRadius: '7px'
                      }}>
                    <Typography variant="h5">Ende</Typography>
                </Grid>
                <Grid container my={3} display="flex" justifyContent="end">
                    <Grid item xs={12} md={4} display="flex" justifyContent="center">
                        <Typography variant="h5">Total : </Typography>
                    </Grid>
                    <Grid item xs={12} md={2} display="flex" justifyContent="center">
                        <Typography variant="h5">CHF {choosedTourDetail.price}</Typography>
                    </Grid>
                </Grid>
            </Timeline>
        </div>
    );
}

export default TourSiteProposal;
