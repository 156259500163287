import React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import imgRoutePlanner1 from '../../assets/Images/tourservice-tutorial4.jpg'
import imgRoutePlanner2 from '../../assets/Images/tourservice-tutorial5.jpg'
import {PaletteMode} from "@mui/material";
import {Link} from "react-router-dom";

const DeineReise = (): JSX.Element => {
  const theme = useTheme();
    const mode = theme.palette.mode;

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
      <Grid container spacing={4}>
        <Grid
          item
          container
          alignItems={'center'}
          justifyContent={'center'}
          xs={12}
          md={6}
          gap={'1rem'}
        >
          <Box
            component={'img'}
            height={1}
            width={1}
            src={imgRoutePlanner1.toString()}
            alt="..."
            boxShadow={0}
            borderRadius={2}
            maxWidth={200}
            sx={{
              filter:
                theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
            }}
          />
          <Box
            component={'img'}
            height={1}
            width={1}
            src={imgRoutePlanner2.toString()}
            alt="..."
            boxShadow={0}
            borderRadius={2}
            maxWidth={200}
            sx={{
              filter:
                theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
            }}
          />
        </Grid>
        <Grid item container xs={12} md={6} alignItems={'center'}>
          <Box>
            <Box marginBottom={2}>
              <Typography
                variant="h3"
                color="text.primary"
                sx={{ fontWeight: 700 }}
              >
                Deine Reise - Deine Regeln:{' '}
                <br />
                <Typography
                  color={'text.primary'}
                  component={'span'}
                  variant={'inherit'}
                  sx={{
                    background: `linear-gradient(180deg, transparent 82%, ${alpha(
                        mode === 'light' as PaletteMode ? theme.palette.secondary.main : theme.palette.primary.main,
                      0.3,
                    )} 0%)`,
                  }}
                >
                  Nutze unseren Reiseplaner
                </Typography>
              </Typography>
            </Box>
            <Box marginBottom={3}>
              <Typography variant="h6" component="p" color="text.secondary">
                Unser Algorithmus nimmt die schönsten Stellplätze einer Region und macht Dir mehrere Vorschläge für Deine perfekte Reise. Doch das ist nicht alles,
                jetzt kommt der Hammer: Du kannst dir einen Reisevorschlag auswählen und ganz nach deinem Geschmack pimpen. Hier
                ein bisschen länger bleiben oder auch eine Unterkunft tauschen. Ganz wie es Dir gefällt!
              </Typography>

            </Box>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'stretched', sm: 'flex-start' }}
            >
              <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  component={Link}
                  to={"/login"}
                  fullWidth={isMd ? false : true}
              >
                Jetzt kostenlos starten...
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
  );
};

export default DeineReise;
