import OfferImages from "../presentationalComponents/OfferImages";
import * as React from "react";
import {Compound} from "../../services/restserver-openapi";
import Card from "@mui/material/Card";
import MissingImage from "../presentationalComponents/MissingImage";
import {Skeleton} from "@mui/material";
import {getReadableCompoundType} from "../../utils/typeUtils";

interface CompoundImagesProps {
    compound: Compound|null;
}

export function CompoundImages({compound}: CompoundImagesProps): React.ReactElement {
    if (compound === null) {
        return <Skeleton variant="rounded" width="100%" height={290}/>;
    }

    return (
        <>
            {/*<Typography variant="h5" component="h3" mt={1}>Bilder</Typography>*/}
            {compound.imageIds && compound.imageIds.length > 0
                ? <OfferImages imageIds={compound.imageIds} altText={"Bild vom/von " + getReadableCompoundType(compound) + " " + compound.name}/>
                : <Card><MissingImage text={"Keine Bilder vorhanden."}></MissingImage></Card>
            }
        </>
    );
}