import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import {alpha, useTheme} from '@mui/material/styles';
import SVGComponent from "../presentationalComponents/SVGComponent";
import {PaletteMode} from "@mui/material";



const mock = [
  {
    title: 'Vanlife als Lebensgefühl',
    subtitle:
        'Vanlife ist mehr als Camping. Es ist ein Lebensgefühl, das Freiheit und Abenteuer verkörpert. Dieses Gefühl fördert eine tiefe Verbindung zur Natur und schafft Raum für Individualität und Entschleunigung.',
    icon: 'camper',
  },
  {
    title: 'Reiseplanung easy-peasy',
    subtitle:
      'Vergiss aufwendige Ferienplanungen! Hier hast du eine komplette Reise von Anfang bis Ende. Du bekommst nicht nur einzelne Übernachtungen, sondern vollständige Reisen von uns.',
    icon: 'route',
  },
  {
    title: 'Du sparst sehr viel Zeit',
    subtitle:
      'Unser Angebot macht Schluss mit langwierigem Suchen und Anfragen und hilft dir, beim Planen Deiner Reise viel Zeit zu sparen. So kannst du dich auf das Wesentliche konzentrieren und hast mehr Zeit für dein Reiseabenteuer.',
    icon: 'timefast',
  },
  {
    title: 'Individuell für Dich',
    subtitle:
      'Du bekommst von uns einzigartige Reisevorschläge, die Du individuell nach Deinen Wünschen anpassen kannst. Alles digital, alles innerhalb von Sekunden. Alles mit einem Klick (na ja, je nachdem wie viele Sonderwünsche du hast)!',
    icon: 'personcheck',
  },
  {
    title: 'Wir sind jederzeit für Dich da.',
    subtitle:
      'Du kannst unser Planungstool jederzeit verwenden und Deine Reise kann jederzeit losgehen. Persönliche Betreuung von uns gibt es obendrauf! Reiseabenteuer 24/7 - und das ganz OHNE Stress!',
    icon: 'openinghours',
  },
  // START
  {
    title: 'Wir sind Swisstainable',
    subtitle:
      'Eine intakte Natur ist wichtig und fördert Gesundheit und innere Zufriedenheit. Deshalb sind wir voll dabei: Swisstainable ist ein Nachhaltigkeitsprogramm vom Schweizer Tourismus mit Fokus auf nachhaltiger Entwicklung.',
    icon: 'swisstainable',
  },
  {
    title: 'Neue Orte, coole Leute',
    subtitle:
      'Vanlife ist ein Lebensgefühl und steht für Reiseabenteuer mit und für Menschen. Triff andere Vanlifer und hilf uns beim Finden neuer Gastgeber oder toller Orte und profitiere von einem grösseren Angebot für Deine Reisen.',
    icon: 'neueorte',
  },
  {
    title: 'Open Travel Community',
    subtitle:
      'Von Dir - für Dich. Wir stehen für Gemeinsamkeit und wollen mit Dir wachsen. Bau mit uns eine Open Travel Community auf und lasst uns gemeinsam tolle Erlebnisse schaffen oder bei einem Drink spannende Geschichten teilen.',
    icon: 'community',
  },
];

const Advantages = (): JSX.Element => {
  const theme = useTheme();
  const mode = theme.palette.mode;

  return (
    <Box>
      <Box marginBottom={4}>
        <Box marginBottom={2}>
          <Typography
              align={'center'}
              variant="h3"
              color="text.primary"
              sx={{ fontWeight: 700 }}
          >
            Wir bieten Dir viele{' '}
            <Typography
                color={'text.primary'}
                component={'span'}
                variant={'inherit'}
                sx={{
                  background: `linear-gradient(180deg, transparent 82%, ${alpha(
                      mode === 'light' as PaletteMode ? theme.palette.secondary.main : theme.palette.primary.main,
                      0.3,
                  )} 0%)`,
                }}
            >
              Vorteile
            </Typography>
          </Typography>
        </Box>
        <Typography
          variant="h6"
          align={'center'}
          color={'text.secondary'}
          data-aos={'fade-up'}
        >
          Don’t worry - be happy. Wir haben eine supercoole Lösung für dich!
          {/*<br />*/}
          {/*Unseren unwiderstehlichen Reiseplaner, den es so noch nirgends gibt. Damit wird die Planung deines*/}
          {/*Camping-Abenteuers mega easy!*/}
        </Typography>
      </Box>
      <Grid container spacing={4}>
        {mock.map((item, i) => (
          <Grid key={i} item xs={12} md={3}>
            <ListItem
              component="div"
              disableGutters
              data-aos={'fade-up'}
              data-aos-delay={i * 100}
              data-aos-offset={100}
              data-aos-duration={600}
              sx={{
                flexDirection: 'column',
                alignItems: 'center',
                padding: 0,
              }}
            >
              <Box
                component={ListItemAvatar}
                marginBottom={1}
                minWidth={'auto !important'}
              >
                <Box color={theme.palette.primary.main} >
                  <SVGComponent iconValue={item.icon} size={40}/>
                </Box>
              </Box>
              <ListItemText
                primary={item.title}
                secondary={item.subtitle}
                primaryTypographyProps={{
                  variant: 'h6',
                  gutterBottom: true,
                  align: 'center',
                }}
                secondaryTypographyProps={{ align: 'center' }}
                sx={{
                  '& .MuiListItemText-primary': {
                    fontWeight: 700,
                  },
                  margin: 0,
                }}
              />
            </ListItem>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Advantages;
