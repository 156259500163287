import React, {useEffect, useState} from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Link,
    Typography,
    Box,
    Chip,
    TextField, Select, MenuItem, Button, InputLabel,
    FormControl, TableFooter, TablePagination
} from '@mui/material';
import {
    billingPeriodUnitGerman,
    getSubscriptionName,
    getTotalPriceWithoutCharges, isEarlyAccessSubscription, statusColor,
    statusGerman
} from "../../utils/subscriptionUtils";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {getUserOverviewAsync, setPageSize} from "../../store/Agent/userOverviewSlice";
import { User } from '../../services/restserver-openapi';
import { Link as RouterLink } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';



const UserTable = ({ userRole }: { userRole: User.userRole }) => {
    const dispatch = useAppDispatch();
    const userOverview = useAppSelector((state) => state.userOverview.userOverview);
    const pageSize = useAppSelector((state) => state.userOverview.pageSize);
    const totalEntries = useAppSelector((state) => state.userOverview.totalEntries);
    const [filter, setFilter] = useState('');
    const [orderBy, setOrderBy] = useState('userId');
    const [direction, setDirection] = useState<'ASC' | 'DESC'>('ASC');
    const [pageNumber, setPageNumber] = useState(0);

    useEffect(() => {
        dispatch(getUserOverviewAsync({ userRole, filter, pageNumber, pageSize, direction, orderBy }));
    }, [dispatch, userRole, filter, pageNumber, pageSize, direction, orderBy]);



    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPageNumber(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch(setPageSize(parseInt(event.target.value, 10)));
        setPageNumber(0);
    };

    return (
        <Box>
            <Box display="flex" alignItems="center" justifyContent="space-between" gap={4} >
                <Box display="flex" gap={2} alignItems="center">
                    <Typography variant="subtitle1" component="span">
                        <Box component="span" fontWeight="bold">{totalEntries}</Box> Einträge
                    </Typography>
                    <TextField
                        placeholder="Name, E-Mail, Chargebee-ID"
                        label="Search Filter"
                        margin={'dense'}
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                        size="small"
                    />
                </Box>
                <Box display="flex" gap={2} alignItems="center">
                    <Box>
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel id="order-by-label">Sortieren nach</InputLabel>
                        <Select
                            labelId="order-by-label"
                            label="Sortieren nach"
                            autoWidth
                            value={orderBy}
                            onChange={(e) => setOrderBy(e.target.value)}
                        >
                            <MenuItem value={'userId'}>userId</MenuItem>
                            <MenuItem value={'chargebeeId'}>Chargebee-ID</MenuItem>
                            <MenuItem value={'person.firstname'}>Vorname</MenuItem>
                            <MenuItem value={'person.lastname'}>Nachname</MenuItem>
                            <MenuItem value={'person.email'}>E-Mail</MenuItem>
                        </Select>
                        </FormControl>

                    </Box>
                    <Box>
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                            <InputLabel id="direction-label">Sortierrichtung</InputLabel>
                            <Select
                                labelId="direction-label"
                                label="Sortierrichtung"
                                autoWidth
                                value={direction}
                                onChange={(e) => setDirection(e.target.value as 'ASC' | 'DESC')}
                            >
                                <MenuItem value={'ASC'}>Aufsteigend</MenuItem>
                                <MenuItem value={'DESC'}>Absteigend</MenuItem>
                            </Select>

                        </FormControl>
                    </Box>
                </Box>
            </Box>

            <TableContainer component={Paper}>
            <Table>
                <TableHead sx={{ bgcolor: 'alternate.main' }}>
                    <TableRow>
                        <TableCell><Typography
                            color={'text.secondary'}
                            variant={'caption'}
                            fontWeight={700}
                            sx={{ textTransform: 'uppercase' }}
                        >User-ID</Typography></TableCell>

                        <TableCell><Typography
                            color={'text.secondary'}
                            variant={'caption'}
                            fontWeight={700}
                            sx={{ textTransform: 'uppercase' }}
                        >Chargebee-ID</Typography></TableCell>
                        <TableCell><Typography
                            color={'text.secondary'}
                            variant={'caption'}
                            fontWeight={700}
                            sx={{ textTransform: 'uppercase' }}
                        >Vorname</Typography></TableCell>
                        <TableCell><Typography
                            color={'text.secondary'}
                            variant={'caption'}
                            fontWeight={700}
                            sx={{ textTransform: 'uppercase' }}
                        >Nachname</Typography></TableCell>

                        <TableCell><Typography
                            color={'text.secondary'}
                            variant={'caption'}
                            fontWeight={700}
                            sx={{ textTransform: 'uppercase' }}
                        >E-Mail</Typography></TableCell>
                        <TableCell><Typography
                            color={'text.secondary'}
                            variant={'caption'}
                            fontWeight={700}
                            sx={{ textTransform: 'uppercase' }}
                        >Adresse</Typography></TableCell>
                        <TableCell><Typography
                            color={'text.secondary'}
                            variant={'caption'}
                            fontWeight={700}
                            sx={{ textTransform: 'uppercase' }}
                        >Abo</Typography></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {userOverview.map((user) => (
                        <TableRow key={user.chargebeeId}
                                  sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                            '&:nth-of-type(2n)': { bgcolor: 'alternate.main' },
                        }}
                        >
                            <TableCell>{user.userId}</TableCell>
                            <TableCell>
                                <Link href={`https://${process.env.REACT_APP_CHARGEBEE_SITE}.chargebee.com/d/customers/${user.chargebeeId}`} target="_blank" rel="noopener noreferrer">
                                    {user.chargebeeId}
                                </Link>
                            </TableCell>
                            <TableCell>{user.firstName}</TableCell>
                            <TableCell>{user.lastName}</TableCell>
                            <TableCell>{user.email}</TableCell>
                            <TableCell>
                                {user.address &&
                                <>
                                {user.address.company} {user.address.company && <br />}
                                <Typography variant="body2">{user.address.line1}</Typography>
                                <Typography variant="body2">{user.address.line2}</Typography>
                                <Typography variant="body2">{user.address.country + "-" + user.address.zipCode} {user.address.city}</Typography>
                                <Typography variant="body2">{user.address.state}</Typography>
                                </>}
                            </TableCell>
                            <TableCell>
                                {user.subscription && user.subscription.id &&
                                    <Box display="flex" gap={2} alignItems="center">
                                        <Chip color={statusColor(user.subscription.status)} label={statusGerman(user.subscription.status)} />
                                        <Link href={`https://${process.env.REACT_APP_CHARGEBEE_SITE}.chargebee.com/d/subscriptions/${user.subscription.id}`} target="_blank" rel="noopener noreferrer">
                                            <Typography variant="body2">{getSubscriptionName(user.subscription)}</Typography>
                                            <Typography variant="caption">{getTotalPriceWithoutCharges(user.subscription)} {!isEarlyAccessSubscription(user.subscription) ? " / " + billingPeriodUnitGerman(user.subscription.billingPeriodUnit) : ""}</Typography>
                                        </Link>
                                    </Box>
                                }
                            </TableCell>
                            <TableCell>
                                <Button
                                    variant="outlined"
                                    startIcon={<InfoIcon />}
                                    component={RouterLink}
                                    to={`/travelAgent/user/${user.userId}/${user.userRole}`}
                                >
                                    Details
                                </Button>
                            </TableCell>

                        </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={9}>
                        <TablePagination
                            component="div"
                            count={totalEntries}
                            page={pageNumber}
                            onPageChange={handleChangePage}
                            rowsPerPage={pageSize}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            labelRowsPerPage="Einträge pro Seite"
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} von ${count}`}
                        />
                        </TableCell>
                    </TableRow>
                </TableFooter>

            </Table>
        </TableContainer>
        </Box>
    );
};

export default UserTable;