import React from 'react';
import { useAppSelector } from '../../hooks/hooks';
import { Box, Checkbox, FormControlLabel, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { CompoundImages } from '../campsiteDetailComponents/CompoundImages';
import HereMapWithMarker from '../presentationalComponents/HereMapWithMarker';
import CompoundsListing from '../presentationalComponents/CompoundsListing';
import { CompoundDetailCard } from '../campsiteDetailComponents/CompoundDetailCard';
import RvHookupIcon from '@mui/icons-material/RvHookup';
import SitesListing from '../presentationalComponents/SitesListing';
import { EcoFriendlyIcon } from '../vanliferComponents/EcoFriendlyIcon';
import {getReadableCompoundType} from "../../utils/typeUtils";


function CompoundComponent() {
  const compound = useAppSelector(state => state.compounds.selectedCompound);

  return (
    <Grid container>
      <Grid container>
        <Grid item xs={8}>
          <Grid container>
            <Grid item xs={12} sm={3}>
              <Typography component="p" variant="body1" style={{ fontWeight: 'bold' }}>Typ:</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Typography component={'p'} variant="body1">{getReadableCompoundType(compound)}</Typography>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Typography component="p" variant="body1" style={{ fontWeight: 'bold' }}>Name:</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Typography component={'p'} variant="body1">{compound.name}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography component="p" variant="body1" style={{ fontWeight: 'bold' }}>Website:</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Typography component={'p'} variant="body1"><a href={compound.externalLink} target="_blank"
                                                             rel="noreferrer">{compound.externalLink}</a></Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography component="p" variant="body1" style={{ fontWeight: 'bold' }}>Telefon:</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Typography component={'p'} variant="body1">{compound.phone}</Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={3}>
              <Typography component="p" variant="body1" style={{ fontWeight: 'bold' }}>Adresse:</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Typography component={'p'}
                          variant="body1">{compound.addressLine1 + ', ' + compound.zipCode + ' ' + compound.city + ', ' + compound.country}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography component="p" variant="body1" style={{ fontWeight: 'bold' }}>Koordinaten:</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Typography component={'p'}
                          variant="body1">{'lat:' + compound.coordinates?.x?.toFixed(5) + ', long:' + compound.coordinates?.y?.toFixed(5)}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography component="p" variant="body1" style={{ fontWeight: 'bold' }}>Regionen:</Typography>
            </Grid>

            <Grid item xs={12} sm={9}>
              <Typography component={'p'}
                          variant="body1">{compound.regions?.map(region => region.name).join(',')}</Typography>
            </Grid>
          </Grid>
          <Typography id="modal-description" sx={{ whiteSpace: 'pre-line' }}><br /></Typography>
        </Grid>
        <Grid item xs={12}>
          {compound.coordinates?.x && compound.coordinates?.y &&
            <HereMapWithMarker mapMarker={{ latitude: compound.coordinates.x, longitude: compound.coordinates.y }}
                               width={window.innerWidth.toString()} height="250px" />}

        </Grid>
      </Grid>
      <Typography id="modal-description" sx={{ whiteSpace: 'pre-line' }}><br /></Typography>
      <Grid container>
        <Grid item xs={12} sm={4}>
          <Typography component="p" variant="body1" style={{ fontWeight: 'bold' }}>Standard Check-In Zeit:</Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography component={'p'} variant="body1">{compound.arrivalTime}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography component="p" variant="body1" style={{ fontWeight: 'bold' }}>Standard Check-Out Zeit:</Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography component={'p'} variant="body1">{compound.departureTime}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography component="p" variant="body1" style={{ fontWeight: 'bold' }}>Klassifikation:</Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography component={'p'} variant="body1">{compound.classification}</Typography>
        </Grid>
        <Typography id="modal-description" sx={{ whiteSpace: 'pre-line' }}><br /></Typography>
        <Grid item xs={12} sm={12}>
          <Typography variant="body1" style={{ fontWeight: 'bold' }}>Beschreibung des Betriebs:</Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography component={'p'} variant="body1">{compound.guestInformation}</Typography>
        </Grid>
        <Typography id="modal-description" sx={{ whiteSpace: 'pre-line' }}><br /></Typography>
        <Grid item xs={12} sm={12}>
          <Typography variant="body1" style={{ fontWeight: 'bold' }}>Ausstattungen:</Typography>
        </Grid>
        <Grid item xs={12} sm={10}>
          <Grid item xs={12} sm={10}>
            {[
              {attribute: compound?.wifiAvailable, label: "Wifi / WLAN"},
              {attribute: compound?.sauna, label: "Sauna"},
              {attribute: compound?.massage, label: "Massage od. Wellness"},
              {attribute: compound?.hotTubOrSpa, label: "Whirlpool / SPA Pool"},
              {attribute: compound?.swimmingPool, label: "Schwimmbad"},
              {attribute: compound?.cityNearby, label: "Stadt in der Nähe"},
              {attribute: compound?.mountains, label: "Berge in der Nähe"},
              {attribute: compound?.waterbody, label: "Gewässer in der Nähe"},
              {attribute: compound?.culturalSiteOrAttraction, label: "Kultur / Sehenswürdigkeit in der Nähe"},
              {attribute: compound?.cateringOrRestaurant, label: "Verpflegung / Restaurant"},
              {attribute: compound?.onlyBreakfastPossible, label: "Nur Frühstück möglich"},
              {attribute: compound?.breadRollService, label: "Brötchenservice"},
              {attribute: compound?.wholeDayReception, label: "24h Rezeption"},
              {attribute: compound?.ownParkingSpace, label: "Eigener Parkplatz"},
              {attribute: compound?.parkingNearby, label: "Parkplatz in der Nähe"},
              {attribute: compound?.publicTransportation, label: "ÖV in der Nähe"},
              {attribute: compound?.sustainable, label: "Nachhaltiger Betrieb"},
              {attribute: compound?.childFriendly, label: "Kinderfreundlich"},
              {attribute: compound?.shopOrSupermarket, label: "Shop / Supermarkt in der Nähe"},
              {attribute: compound?.playGround, label: "Spielplatz"},
              {attribute: compound?.wasteWaterDisposal, label: "Abwasser/Entsorgung"},
              {attribute: compound?.freshWater, label: "Frischwasser"},
              {attribute: compound?.sportOrAnimation, label: "Sport / Animation"},
              {attribute: compound?.grillOrBarbecueArea, label: "Grill/ Grillstelle"},
            ].map(({attribute, label}) =>
                attribute ? (
                    <FormControlLabel
                        key={label}
                        control={<Checkbox checked={attribute} disabled />}
                        label={label}
                    />
                ) : null
            )}
        </Grid>
      </Grid>
      <Typography id="modal-description" sx={{ whiteSpace: 'pre-line' }}><br /></Typography>
      <Grid item xs={12} sm={12}>
        <Typography variant="body1" style={{ fontWeight: 'bold' }}>Bilder des Betriebs:</Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <CompoundImages compound={compound} />
      </Grid>
      <Typography id="modal-description" sx={{ whiteSpace: 'pre-line' }}><br /></Typography>
      <Grid item xs={12} sm={12}>
        <Typography variant="body1" style={{ fontWeight: 'bold' }}>Unterkünfte des Betriebs:</Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        {compound?.compoundId && <SitesListing compoundId={compound.compoundId} />}
      </Grid>
    </Grid>
    </Grid>
  );
}

export default CompoundComponent;
