/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import imgEarlyAccess from '../../assets/Images/busing-Zyx1bK9mqmA-unsplash_small.jpg';
import {Link} from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material";

const EarlyAccess = (): JSX.Element => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    return (
        <Box position={'relative'}>
            <Grid container>
                <Grid item xs={12} sm={6} data-aos={'fade-up'}>
                    <Box marginBottom={2}>
                        <Typography
                            variant="h4"
                            color="text.primary"
                            fontWeight={700}
                        >
                            Early Access
                        </Typography>
                    </Box>
                    <Box marginBottom={2}>
                        <Typography variant="h6" color={'text.primary'} data-aos={'fade-up'}>
                            Bringe Dich in unsere Entwicklung ein
                        </Typography>
                    </Box>
                    <Box marginBottom={3}>
                        <Typography
                            gutterBottom
                            variant="body1"
                            component="p"
                            color="text.primary"
                            fontWeight={700}
                        >
                            Das Vanlife Travel Angebot ist noch lange nicht fertig und wird sich in Zukunft
                            auch noch stark verändern.
                        </Typography>
                        <Typography
                            gutterBottom
                            variant="body1"
                            component="p"
                            color="text.primary"
                        >
                            Mit unserem Early Access Angebot bieten wir eine großartige Möglichkeit, begeisterte Reisende in unseren
                            Entwicklungsprozess einzubinden, um unseren Reiseplaner und die Open Travel Community
                            weiterzuentwickeln und zu verbessern. Werde Teil von Vanlife Travel, erhalte exklusive News und profitiere als Erster von
                            neuen Funktionen. Gerne möchten wir auch alle Eure Vorschläge und Fehlermeldungen sowie Feedback hören.
                            Wir freuen uns darauf, mit Euch zusammenzuarbeiten.
                        </Typography>
                        <Typography
                            gutterBottom
                            variant="body1"
                            component="p"
                            color="text.primary"
                            fontWeight={700}
                        >
                            Wir freuen uns darauf, mit Euch zusammenzuarbeiten.
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
            <Box
                component={'img'}
                src={imgEarlyAccess}
                sx={{
                    maxWidth: '500px',
                    height: 'auto',
                    position: 'absolute',
                    bottom: '30px',
                    right: 0,
                    display: {xs: 'none', sm: 'block'},
                }}
            />
        </Box>
    );
};

export default EarlyAccess;
